// const { isTemplateExpression } = require("typescript");

var userInControl = true;
(function () {

  if (typeof window.Event === "function") return false;

  function Event(event, params) {
    //params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('Event');
    evt.initEvent(event, true, false);
    //evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  Event.prototype = window.Event.prototype;

  window.Event = Event;
})();

function Annotation(OpenSeadragon) {
  "use strict";

  Annotation.OSD = OpenSeadragon;

  return function (id, canvasDimensions) {
    return new Annotation.AnnoBoard(id, canvasDimensions);
  };
}

(function (ann) {
  "use strict";

  var hasTouch = !!window.hasOwnProperty('ontouchstart'),
    ACTIONS = {
      DOWN: hasTouch ? "touchstart" : "mousedown",
      MOVE: hasTouch ? "touchmove" : "mousemove",
      UP: hasTouch ? "touchend" : "mouseup",
      LEAVE: hasTouch ? "touchleave" : "mouseleave"
    };

  ann.actions = function (overlayElem) {
    this.baseElement = overlayElem;
    this.isActionStarted = false;
    this.isActionCompleted = false;
    this.implementedAction = null;
  };

  ann.actions.prototype.attachActions = function () {
    var selfActions = this;

    function getRelativePoints(x, y) {
      var parentPos = selfActions.baseElement.getClientRects()[0];
      return {
        x: x - parentPos.left,
        y: y - parentPos.top
      };
    }

    this.mousedownListener = function (event) {
      selfActions.isActionStarted = true;
      selfActions.isActionCompleted = false;
      selfActions.baseElement.width = parseFloat(selfActions.baseElement.getClientRects()[0].width);
      selfActions.baseElement.height = parseFloat(selfActions.baseElement.getClientRects()[0].height);
      if (window.getSelection) {
        if (window.getSelection().empty) { // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) { // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) { // IE?
        document.selection.empty();
      }
    };

    this.mousemoveListener = function (event) {
      if ((selfActions.isActionStarted && !selfActions.isActionCompleted) ||
        (selfActions.implementedAction.type == "Angle" && selfActions.implementedAction.points[1] && selfActions.isActionCompleted)) {
        if (!event.x || !event.y) {
          event.x = event.touches[0].clientX;
          event.y = event.touches[0].clientY;
        }
        selfActions.implementedAction.actionChangeBehavior(selfActions.baseElement, getRelativePoints(event.x, event.y));
      }
    };

    this.mouseupListener = function (event) {
      selfActions.isActionStarted = false;
      selfActions.isActionCompleted = true;
      if (!event.x || !event.y) {
        event.x = event.touches[0].clientX;
        event.y = event.touches[0].clientY;
      }
      // selfActions.implementedAction.osdObj.setMouseNavEnabled(true);
      selfActions.implementedAction.actionCompleteBehavior(selfActions.baseElement, getRelativePoints(event.x, event.y));
      if (!selfActions.implementedAction.marker) {
        if (selfActions.implementedAction.type != "Angle" ||
          (selfActions.implementedAction.type == "Angle" && selfActions.implementedAction.points[2])) {
          var promise = ann.Editor("onDrawn", {
            x: selfActions.implementedAction.type != "Circular" ? event.x : selfActions.implementedAction.centerX,
            y: selfActions.implementedAction.type != "Circular" ? event.y : selfActions.implementedAction.centerY
          }, selfActions.implementedAction, selfActions.baseElement.parentNode, selfActions.isSplitView);
          promise.then(function () {
            selfActions.baseElement.style.display = "none";
          }, function () {
            selfActions.baseElement.style.display = "none";
          }).catch(function () {
            selfActions.baseElement.style.display = "none";
          });
        }
      } else {
        if (selfActions.implementedAction && selfActions.implementedAction.onSave) {
          selfActions.implementedAction.onSave('');
          var onAnnotationSave = typeof Event === "function" ? new Event('onAnnotationSave') : window.Event;
          onAnnotationSave.annotation = selfActions.implementedAction;
          selfActions.baseElement.parentNode.dispatchEvent(onAnnotationSave);
        }
        selfActions.baseElement.style.display = "none";
      }
    };

    this.mouseleaveListener = function (event) {
      if (selfActions.isActionStarted && !selfActions.isActionCompleted) {
        selfActions.mouseupListener(event);
        if (window.getSelection) {
          if (window.getSelection().empty) { // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) { // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) { // IE?
          document.selection.empty();
        }
      }
    };

    this.baseElement.addEventListener(ACTIONS.DOWN, this.mousedownListener);
    this.baseElement.addEventListener(ACTIONS.MOVE, this.mousemoveListener);
    this.baseElement.addEventListener(ACTIONS.UP, this.mouseupListener);
    this.baseElement.addEventListener(ACTIONS.LEAVE, this.mouseleaveListener);
  };

  ann.actions.prototype.detachActions = function () {
    this.baseElement.removeEventListener(ACTIONS.DOWN, this.mousedownListener);
    this.baseElement.removeEventListener(ACTIONS.MOVE, this.mousemoveListener);
    this.baseElement.removeEventListener(ACTIONS.UP, this.mouseupListener);
    this.baseElement.removeEventListener(ACTIONS.LEAVE, this.mouseleaveListener);
  };

  ann.actions.prototype.setBehavior = function (implementedAction) {
    //To Do: validate allowed if implementedAction is among the allowed ones
    this.implementedAction = implementedAction;
  };

}(Annotation));

(function (ann) {
  "use strict";
  var editorHTML =
    '<div class="anotatepop" id="annotation-popup">' +
    '<form>' +
    '<div style="clear: both; width:100%">' +
    '<div class="float-left">' +
    '<label>Comment</label>' +
    '</div>' +
    '<div class="icon_del_bokmark float-right">' +
    '<i class="fa fa-pencil" aria-hidden="true" id="annotation-popup-button-edit" title="Edit"></i> ' +
    '<i class="fa fa-bookmark icon_bookmark" aria-hidden="true" id="annotation-popup-button-bookmark"></i> ' +
    '<i class="fa fa-trash icon_del" aria-hidden="true" id="annotation-popup-button-delete"></i>' +
    '</div>' +
    '</div>' +
    '<p class="viewer_email_annotatepop" id="popup-user-details" style="padding-bottom: .4vw; clear:both;">zxvxzvzxr@airamatrix.com <i class="fa fa-user colorblue" aria-hidden="true"></i></p>' +
    '<textarea cols="35" rows="3" id="annotation-comments" maxlength="1000"></textarea>' +
    '<div class="area_perim" id="annotation-area-perimeter">' +
    '<div><label>Area: </label><span id="annotation-area" style="margin-left: 0.3vw;"> 20mm<sup>2</sup></span></div>' +
    '<div><label>Perimeter: </label><span id="annotation-perimeter" style=" margin-left: 0.3vw;"> 20mm<sup>2</sup></span></div>' +
    '</div>' +
    '</form>' +
    '</div>' +


    '<div class="anotatepop1 dn" id="annotation-editor1">' +
    '<form>' +
    '<div style="clear: both; width:100%;">' +
    '<div class="float-left">' +
    '<label>Comment</label>' +
    '</div>' +
    '<div class="icon_del_bokmark float-right">' +
    '<i class="fa fa-bookmark" aria-hidden="true" id="bookmarkFlag1"></i> &nbsp;&nbsp;&nbsp;' +
    '</div>' +
    '</div>' +
    '<textarea cols="35" rows="3" id="annotation-editor-text1" maxlength="1000"></textarea>' +
    '<p class="errormsgs" style="clear:both;" id="error-message1"></p>' +
    '<div class="" id="area-perimeter-div">' +
    '<span title="Remove Area & Perimeter from Annotation" id="areaPerimeterFlag1" style="padding-right: .5vw;"><i class="fa fa-eye-slash"></i></span>' +
    '<div id="editor-area-perimeter1" class="area_perim" style="display:block">' +
    '<div><label>Area: </label><span id="editor-area1" style="margin-left: 0.3vw;"> 20mm<sup>2</sup></span></div>' +
    '<div><label>Perimeter: </label><span id="editor-perimeter1" style="margin-left: 0.3vw;"> 20mm<sup>2</sup></span></div>' +
    '</div>' +
    '</div>' +
    '<div class="row filterbtn">' +
    '<div class="col-md-12 filterbtn" style="padding-right: 0;">' +
    '<button type="button" class="btn orangebtn savebtn" aria-label="searchbtn" style="padding: .42vw 1.3vw;margin-left: .7vw;" id="annotation-editor-button-save1">Save</button>' +
    '<button type="button" class="btn btn-default" style="padding: .5vw 1.3vw;" aria-label="cancelbtn" id="annotation-editor-button-cancel1">Close</button>' +
    '</div>' +
    '</div>' +
    '</form>' +
    '</div>' +


    '<div class="annoEditDiv anotatepop" id="annotation-editor">' +
    '<div class="iconActions clearfix">' +
    '<div class="float-right editAnnotationIcons">' +
    '<span title="Remove Area & Perimeter from Annotation" id="areaPerimeterFlag" class="areaPerimeterIcon"><i class="fa fa-eye-slash annoInfo"></i></span>' +
    '<i class="fa fa-bookmark annoInfo bookMarkIcon" id="bookmarkFlag"></i>' +
    '<i class="fa fa-floppy-o annoInfo saveAnnotation" id="annotation-editor-button-save"></i>' +
    '<i class="fa fa-times annoInfo closeAnnotation" id="annotation-editor-button-cancel"></i>' +
    '</div>' +
    '</div>' +
    '<div class="clearfix">' +
    '<div class="annoCreatedBy float-left"><span class="annoCreatedByTitle">Created By</span><span id="createdBy"></span></div>' +
    '<div class="float-right annoColorPicker">' +
    '<input id="annoColorEdit" type="color">' +
    '</div>' +
    '</div>' +
    '<div class="annoCommentDiv">' +
    '<textarea class="form-control" id="annotation-editor-text" rows="2"></textarea>' +
    '<p class="errormsgs" style="clear:both;" id="error-message"></p>' +
    '</div>' +
    '<div class="annoAreaParameterDiv clearfix" id="editor-area-perimeter">' +
    '<div class="areaInfo float-left"><span><b>Area: </b></span><span id="editor-area"></span></div>' +
    '<div class="perimeterInfo float-right"><span><b>Perimeter: </b></span><span id="editor-perimeter"></span></div>' +
    '</div>' +
    '</div>',

    editorElement = document.createElement("div"),
    onAnnotationSave = typeof Event === "function" ? new Event('onAnnotationSave') : window.Event,
    onAnnotationDelete = typeof Event === "function" ? new Event('onAnnotationDelete') : window.Event,
    onAnnotationEdit = typeof Event === "function" ? new Event('onAnnotationEdit') : window.Event,
    onAnnotationCancel = typeof Event === "function" ? new Event('onAnnotationCancel') : window.Event,
    isEditingProgress = false,
    isHighlighted = false,
    validCommentLen = 65000;
  ann.Editor = function (userAction, position, implementedAction, baseElement) {
    if (isEditingProgress && userAction !== "edit") {
      //do something
      return;
    }
    var isMarker = implementedAction.marker;

    editorElement.innerHTML = editorHTML;
    editorElement.style.position = "absolute";
    editorElement.style.zIndex = "999999998";
    editorElement.style.display = "block";
    editorElement.classList.add("editorElement");

    document.getElementById(implementedAction.osdId).appendChild(editorElement);

    var editorOut = true,
      annotationOut = false;
    editorElement.firstChild.style.position = "fixed";
    return new Promise(function (resolve, reject) {
      if (userAction === "highlight") {
        isHighlighted = true;
        document.getElementById("annotation-comments").disabled = true;
        document.getElementById("annotation-comments").innerHTML = implementedAction.comment;
        document.getElementById("popup-user-details").innerHTML = implementedAction.user;
        document.getElementById("annotation-area").innerHTML = implementedAction.area.value + " " + implementedAction.area.unit;
        document.getElementById("annotation-perimeter").innerHTML = implementedAction.perimeter.value + " " + implementedAction.perimeter.unit;
        if (window.location.pathname.includes('consultation')) {
          if (implementedAction.user == JSON.parse(localStorage.getItem('currentUser')).userName && eval(localStorage.getItem('isPresenter'))) {
            $('#annotation-popup-button-delete, #annotation-popup-button-edit').show()
          } else {
            $('#annotation-popup-button-delete, #annotation-popup-button-edit').hide();
          }
        }
        if (implementedAction.areaPerimeterFlag) {
          document.getElementById('annotation-area-perimeter').style.display = 'block';
        } else {
          document.getElementById('annotation-area-perimeter').style.display = 'none';
        }
        if (implementedAction.bookmarkFlag) {
          document.getElementById('annotation-popup-button-bookmark').style.display = 'block';
        } else {
          document.getElementById('annotation-popup-button-bookmark').style.display = 'none';
        }
        editorElement.style.width = "auto";
        editorElement.style.height = "auto";

        var popupStyles = document.getElementById("annotation-popup").style;
        popupStyles.display = "block";
        document.getElementById("annotation-editor").style.display = "none";
        popupStyles.padding = "10px";
        popupStyles.margin = "7px";

        if (!userInControl || implementedAction.user == "system.analysis@airamatrix.com") {
          document.getElementById("annotation-popup-buttons").style.display = "none";
        }

        ann.annotationOut = false;

        editorElement.firstElementChild.addEventListener("mouseenter", function (e) {
          editorOut = false;
        });

        editorElement.firstElementChild.addEventListener("mouseleave", function (e) {
          editorOut = true;
          if (ann.annotationOut) {
            ann.hideEditor();
            ann.annotationOut = false;
            editorOut = false;
          }
        });

        function shouldHideEditor() {
          if (editorOut && ann.annotationOut) {
            ann.hideEditor();
            ann.annotationOut = false;
            editorOut = false;
          }
        }

        function annotationLeave(ev) {
          ann.annotationOut = true;
          setTimeout(shouldHideEditor, 20);
          if (implementedAction.type == "Arrow") {
            implementedAction.element.childNodes[0].childNodes[0].style.outlineStyle = "none";
            implementedAction.element.childNodes[0].childNodes[0].style.outlineOffset = "0px";
          } else if (implementedAction.type == "Ruler") {
            implementedAction.element.childNodes[0].style.outlineStyle = "none";
            implementedAction.element.childNodes[0].style.outlineOffset = "0px";
          } else {
            implementedAction.element.style.outlineStyle = "none";
            implementedAction.element.style.outlineOffset = "0px";
          }
          implementedAction.element.removeEventListener("mouseleave", annotationLeave);
        }

        implementedAction.element.addEventListener("mouseleave", annotationLeave);

        var viewportContainerSize = {
          width: $("#" + implementedAction.osdId).width(),
          height: $("#" + implementedAction.osdId).height(),
        }

        var annotatePopupTopPos = parseFloat(implementedAction.element.style.top);
        var annotatePopupLeftPos = parseFloat(implementedAction.element.style.left);

        if (viewportContainerSize.height < (annotatePopupTopPos + $(implementedAction.element).height() + $("#annotation-popup").height())) {
          annotatePopupTopPos = annotatePopupTopPos - $("#annotation-popup").height() - 20;
        }

        if (viewportContainerSize.width < (annotatePopupLeftPos + $(implementedAction.element).width() + $("#annotation-popup").width())) {
          annotatePopupLeftPos = annotatePopupLeftPos - $("#annotation-popup").width() - 20;
        }

        if (annotatePopupTopPos < 0) {
          annotatePopupTopPos = 0;
        }

        if (annotatePopupLeftPos < 0) {
          annotatePopupLeftPos = 0;
        }

        editorElement.style.top = parseFloat(annotatePopupTopPos) + "px";
        editorElement.style.left = parseFloat(annotatePopupLeftPos) + "px";

        var validAnnotation = false,
          onEdit = function () {
            //do some common code
            if (implementedAction && implementedAction.onEdit) {
              implementedAction.onEdit();
            }
            //To Do: emit onAnnotationCreated event
            editorElement.style.display = "none";
            isEditingProgress = true;
            ann.Editor("edit", position, implementedAction, baseElement);
          },
          onDelete = function () {
            //do some common code
            if (implementedAction && implementedAction.deleteAnnotation) {
              // implementedAction.deleteAnnotation();
              onAnnotationDelete.annotation = implementedAction;
              baseElement.dispatchEvent(onAnnotationDelete);
            }
            //To Do: emit onAnnotationDelete event
            editorElement.style.display = "none";
            //return reject();
          };

        if (!isMarker) {
          // document.getElementById("annotation-popup-button-edit").style.display = "inline-block";
          document.getElementById("annotation-popup-button-edit").addEventListener("click", onEdit);
        } else {
          document.getElementById("annotation-popup-button-edit").style.display = "none";
        }
        document.getElementById("annotation-popup-button-delete").addEventListener("click", onDelete);
      } else {
        editorElement.style.width = document.documentElement.scrollWidth + "px";
        editorElement.style.height = document.documentElement.scrollHeight + "px";
        editorElement.style.top = "0";
        editorElement.style.left = "0";
        editorElement.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
        document.getElementById("annotation-editor").style.display = "block";
        document.getElementById("annotation-popup").style.display = "none";
        if (implementedAction.type == 'Rectangular' || implementedAction.type == 'Circular' || implementedAction.type == 'Ellipse') {
          document.getElementById('area-perimeter-div').style.display = "block";
        } else {
          document.getElementById('area-perimeter-div').style.display = "none";
        }

        if (implementedAction.comment) {
          document.getElementById("annotation-editor-text").value = implementedAction.comment;
        }

        document.getElementById("editor-area").innerHTML = " " + implementedAction.area.value + " " + implementedAction.area.unit;
        document.getElementById("editor-perimeter").innerHTML = " " + implementedAction.perimeter.value + " " + implementedAction.perimeter.unit;
        document.getElementById("createdBy").innerHTML = " " + implementedAction.currentUser;
        document.getElementById("annoColorEdit").value = implementedAction.color;

        if (implementedAction.areaPerimeterFlag) {
          $('#areaPerimeterFlag').attr('title', "Remove Area & Perimeter from Annotation");
          $('#areaPerimeterFlag').find('i').removeClass("fa-eye");
          $('#areaPerimeterFlag').find('i').addClass("fa-eye-slash");
          $('#editor-area-perimeter').show();
        } else {
          $('#areaPerimeterFlag').attr('title', "Add Area & Perimeter to Annotation");
          $('#areaPerimeterFlag').find('i').removeClass("fa-eye-slash");
          $('#areaPerimeterFlag').find('i').addClass("fa-eye");
          $('#editor-area-perimeter').hide();
        }

        $('#annoColorEdit').on("change", function (e) {
          implementedAction.color = e.currentTarget.value;
          $(implementedAction.element).css("border-color",implementedAction.color);
        });

        $('#areaPerimeterFlag').on("click", function () {
          if ($(this).find('i').hasClass("fa-eye-slash")) {
            $(this).find('i').removeClass("fa-eye-slash")
            $(this).find('i').addClass("fa-eye")
            implementedAction.areaPerimeterFlag = false;
            $('#editor-area-perimeter').hide();
            $(this).attr('title', "Add Area & Perimeter to Annotation");
          } else {
            $(this).find('i').removeClass("fa-eye")
            $(this).find('i').addClass("fa-eye-slash")
            implementedAction.areaPerimeterFlag = true;
            $('#editor-area-perimeter').show();
            $(this).attr('title', "Remove Area & Perimeter from Annotation");
          }
        });

        $('#bookmarkFlag').on("click", function () {
          if ($(this).hasClass("icon_bookmark")) {
            $(this).removeClass("icon_bookmark");
            implementedAction.bookmarkFlag = false;
          } else {
            $(this).addClass("icon_bookmark");
            implementedAction.bookmarkFlag = true;
          }
        });

        if (implementedAction.bookmarkFlag) {
          $('#bookmarkFlag').addClass("icon_bookmark");
        } else {
          $('#bookmarkFlag').removeClass("icon_bookmark");
        }

        var onSave = function () {
          //do some common code
          if (implementedAction && implementedAction.onSave) {
            var comment = document.getElementById("annotation-editor-text").value;
            var areaPerimeterFlag = document.getElementById("areaPerimeterFlag").checked;
            var bookmark = document.getElementById("bookmarkFlag").checked;
            // $('#annotation-editor-button-save').text("").append('<span class="spinner-border spinner-border-sm"></span>&nbsp;Saving..');
            $('#annotation-editor-button-save').addClass("savingInProgress");
            implementedAction.onSave(comment, areaPerimeterFlag, bookmark);
            if (!isEditingProgress) {
              if (!onAnnotationSave) {
                onAnnotationSave = window.Event;
              }
              onAnnotationSave.annotation = implementedAction;
              baseElement.dispatchEvent(onAnnotationSave);
            } else {
              onAnnotationEdit.annotation = implementedAction;
              baseElement.dispatchEvent(onAnnotationEdit);
            }

            $('.disabledJoystickrotationNavigator,.disableTopBar, .disableRightBar, .viewerDisableMask').hide();
            $('#ViewerRotate,#ViewerEasyView,#easyViewTab,#rotateTab,#imageAdjustTab,#imageSynch').removeClass('disableFunction');
          }
          //To Do: emit onAnnotationCreated event
          // editorElement.style.display = "none";
          isEditingProgress = false;
          return resolve("saved");
        },
          onCancel = function () {
            //do some common code
            if (implementedAction && implementedAction.deleteAnnotation && !isEditingProgress) {
              $('#ViewerRotate,#ViewerEasyView,#easyViewTab,#rotateTab,#imageAdjustTab,#imageSynch').removeClass('disableFunction');
              $('.disabledJoystickrotationNavigator,.disableTopBar, .disableRightBar, .viewerDisableMask').hide();
              implementedAction.deleteAnnotation();
            }
            //To Do: emit onAnnotationCancel
            onAnnotationCancel.annotation = implementedAction;
            baseElement.dispatchEvent(onAnnotationCancel);

            editorElement.style.display = "none";
            isEditingProgress = false;
            if (implementedAction.id) {
              return false;
            } else {
              return reject("cancelled");
            }
          },
          validateMessage = function () {
            var comment = document.getElementById("annotation-editor-text").value,
              errorMsg = "Please enter some comment",
              errorElement = document.getElementById("error-message"),
              saveButton = document.getElementById("annotation-editor-button-save");
            if (!comment) {
              validAnnotation = false;
              errorElement.innerHTML = errorMsg;
              saveButton.classList.add("disabled");
            } else if (comment.length >= validCommentLen) {
              var e = arguments[0];
              if (comment.length >= validCommentLen) {
                if (e.key != "Backspace" && e.key != "Delete") {
                  e.preventDefault();
                  validAnnotation = false;
                  errorElement.innerText = "Maximum characters limit of 1,000 is reached";
                  saveButton.classList.add("disabled");
                } else if (comment.length < validCommentLen) {
                  validAnnotation = true;
                  errorElement.innerHTML = "";
                  saveButton.classList.remove("disabled");
                }
              }
            } else {
              validAnnotation = true;
              errorElement.innerHTML = "";
              saveButton.classList.remove("disabled");
            }
          };
        document.getElementById("annotation-editor-text").addEventListener("keyup", validateMessage);
        document.getElementById("annotation-editor-text").addEventListener("keydown", validateMessage);
        document.getElementById("annotation-editor-button-save").addEventListener("click", function (e) {
          if ($(this).hasClass("savingInProgress")) {
            return;
          }
          validateMessage(e);
          if (validAnnotation) {
            onSave();
          } else {
            return false;
          }
        });
        document.getElementById("annotation-editor-button-cancel").addEventListener("click", onCancel);
      }
      var annotaionDiv = {
        // left: 5 + parseFloat(implementedAction.element.style.left) + parseFloat($("app-sidebar").closest(".sidebar").is(":visible") ? $("app-sidebar").closest(".sidebar").outerWidth() : 0),
        // top: parseFloat(implementedAction.element.style.top) + parseFloat($("app-header").is(":visible") ? $("app-header").height() : 0),
        left: 5 + parseFloat(implementedAction.element.style.left),
        top: parseFloat(implementedAction.element.style.top),
        width: parseFloat(implementedAction.element.style.width),
        height: parseFloat(implementedAction.element.style.height)
      }

      var viewerOffsetX = $('#' + implementedAction.osdId).offset().left - $('#' + implementedAction.osdId).parent().offset().left;
      var viewerOffsetY = $('#' + implementedAction.osdId).offset().top - $('#' + implementedAction.osdId).parent().offset().top;

      // var viewportContainerSize = {
      //   width: implementedAction.osdObj.viewport.containerSize.x,
      //   height: implementedAction.osdObj.viewport.containerSize.y,
      // }
      var viewportContainerSize = {
        width: $("#" + implementedAction.osdId).width(),
        height: $("#" + implementedAction.osdId).height(),
      }

      // let firstChild = editorElement.firstChild;
      let firstChild = $(editorElement).find("> div:visible")[0];
      let firstChildWidth = $(firstChild).width();
      if (implementedAction.type == "Arrow" || implementedAction.type == "Ruler") {
        annotaionDiv.left = position.x;
        annotaionDiv.top = position.y;
        firstChild.style.top = ((annotaionDiv.top + parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) > (viewportContainerSize.height) ? (annotaionDiv.top - parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) : (annotaionDiv.top)) + "px";
        firstChild.style.left = ((annotaionDiv.left + 310) >= (viewportContainerSize.width) ? (annotaionDiv.left - 310) : (annotaionDiv.left - 5)) + "px";
      } else {
        let headerHeight = $("app-header header").height() ? $("app-header header").height() : 0;
        // firstChild.style.top = ((annotaionDiv.top + parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) > (viewportContainerSize.height) ? (annotaionDiv.top - parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) + (headerHeight) : (annotaionDiv.top) + (headerHeight)) + "px";
        // firstChild.style.left = ((annotaionDiv.left + annotaionDiv.width + 310) >= (viewportContainerSize.width) ? (annotaionDiv.left - 310) : (annotaionDiv.left + annotaionDiv.width - 5)) + "px";
        firstChild.style.top = ((annotaionDiv.top + parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) > (viewportContainerSize.height) ? (annotaionDiv.top - parseFloat($(editorElement).find(".anotatepop:visible").outerHeight())) : (annotaionDiv.top)) + "px";
        firstChild.style.left = ((annotaionDiv.left + annotaionDiv.width + 390) >= (viewportContainerSize.width) ? (annotaionDiv.left - 390) : (annotaionDiv.left + annotaionDiv.width - 5)) + "px";
        firstChild.style.top = (parseFloat(firstChild.style.top) + viewerOffsetY) + "px";
        firstChild.style.left = (parseFloat(firstChild.style.left) + viewerOffsetX) + "px";
      }

      if (parseFloat(firstChild.style.left) < 5) {
        firstChild.style.left = "5px";
      }

      if (parseFloat(firstChild.style.top) < 5) {
        firstChild.style.top = "5px";
      }
    });
  };

  ann.hideEditor = function () {
    if (isHighlighted) {
      if (document.getElementById("annotation-popup")) {
        document.getElementById("annotation-popup").style.display = "none";
      }
      isHighlighted = false;
    }
  };
}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  function updateActionBoundaries(point, boundaries) {
    if (point.x < boundaries.x) {
      boundaries.width += boundaries.x - point.x;
      boundaries.x = point.x;
    } else if (point.x > (boundaries.x + boundaries.width)) {
      boundaries.width = point.x - boundaries.x;
    }
    if (point.y < boundaries.y) {
      boundaries.height += boundaries.y - point.y;
      boundaries.y = point.y;
    } else if (point.y > (boundaries.y + boundaries.height)) {
      boundaries.height = point.y - boundaries.y;
    }
  }

  function getActionBoundaries(points) {
    var pointIndex,
      actionBoundaries = {
        x: points[0].x,
        y: points[0].y,
        width: 0,
        height: 0
      },
      x2 = points[0].x,
      y2 = points[0].y;

    for (pointIndex = 1; pointIndex < points.length; pointIndex = pointIndex + 1) {
      //To Do: See if there is any optimum way of doing this
      var point = points[pointIndex];
      if (point.x < actionBoundaries.x) {
        actionBoundaries.x = point.x;
      } else if (point.x > x2) {
        x2 = point.x;
      }
      actionBoundaries.width = x2 - actionBoundaries.x;
      if (point.y < actionBoundaries.y) {
        actionBoundaries.y = point.y;
      } else if (point.y > y2) {
        y2 = point.y;
      }
      actionBoundaries.height = y2 - actionBoundaries.y;
    }
    return actionBoundaries;
  }

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  function getSlope(p1, p2) {
    return (p2.y - p1.y) / (p2.x - p1.x);
  }

  function zipPoints(points, tolerance) {
    tolerance = tolerance || 1;
    var zippedPointsSet = [points[0], points[1]],
      lastPoint = points[0],
      lastSlope = getSlope(points[1], lastPoint),
      origDatasetLen = points.length,
      ptDist = 3;

    for (var i = 2; i < origDatasetLen; i++) {
      var currentPt = points[i];
      //check for nearness
      if (Math.abs(currentPt.x - lastPoint.x) < ptDist && Math.abs(currentPt.y - lastPoint.y) < ptDist) {
        continue;
      } else {
        //check for slope
        var currentSlope = getSlope(currentPt, lastPoint);
        if (Math.abs(currentSlope - lastSlope) < tolerance) {
          zippedPointsSet.pop();
        }
        zippedPointsSet.push(currentPt);
        lastPoint = currentPt;
        lastSlope = currentSlope;
      }
    }
    return zippedPointsSet;
  }

  ann.freeformAction = function (annotationData, actionBoundaries) {
    this.type = "OpenFreeform";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.areaPerimeterFlag = false;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.points = [];
    this.comment = "";
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.actionBoundaries = actionBoundaries || (this.points && this.points.length > 0 ? getActionBoundaries(this.points) : {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    });
    this.element = null;
  };

  ann.freeformAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.lineWidth = this.lineWidth;
    if (actionCompleted) {
      this.actionBoundaries.x = point.x;
      this.actionBoundaries.y = point.y;
      context.beginPath();
      context.moveTo(point.x, point.y);
      this.points.push(point);
      actionCompleted = false;
    } else {
      context.lineTo(point.x, point.y);
      context.stroke();
      this.points.push(point);
      updateActionBoundaries(point, this.actionBoundaries);
    }
  };

  ann.freeformAction.prototype.reomveRedundantCoOrds = function (redundantDegree) {
    var newPointsArr = [this.points[0]];
    var prevDegree;
    console.log("prev Points Arr length: " + this.points.length);
    for (let a = 1; a < this.points.length; a++) {
      let yPointDiff = (this.points[a].y - newPointsArr[newPointsArr.length - 1].y);
      let xPointDiff = (this.points[a].x - newPointsArr[newPointsArr.length - 1].x);
      let currentDegree = Math.atan2(yPointDiff, xPointDiff) * 360 / Math.PI;
      if (!prevDegree) {
        prevDegree = currentDegree;
      }
      if ((currentDegree < (prevDegree - redundantDegree)) || (currentDegree > (prevDegree + redundantDegree))) {
        prevDegree = currentDegree;
        newPointsArr.push(this.points[a]);
      } else if (a == (this.points.length - 1)) {
        newPointsArr.push(this.points[a]);
      }
    }

    this.points = newPointsArr;
    console.log("curr Points Arr length: " + this.points.length);
  }

  ann.freeformAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    this.points.push(point);
    this.reomveRedundantCoOrds(5);

    var context = baseElement.getContext("2d");
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    context.lineTo(point.x, point.y);
    context.stroke();
    context.closePath();
    // this.points.push(point);
    for (let x = 0; x < this.points.length; x++) {
      this.points[x].y = this.points[x].y - (this.lineWidth / 2);
    }
    updateActionBoundaries(point, this.actionBoundaries);
    this.area = {
      "value": '0.0',
      "unit": "mm"
    };
    this.perimeter = {
      "value": '0.0',
      "unit": "mm"
    };

    this.showAnnotation(this.points, baseElement.parentNode);
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    actionCompleted = true;
  };

  ann.freeformAction.prototype.showAnnotation = function (points, baseElement) {

    var element = document.createElement("div"),
      point1 = points[0],
      ptIndex;

    //points = zipPoints(points, 0.15);

    element.setAttribute("id", "freeform-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    //if this.actionBoundaries not there get them from points and set them
    if (!this.actionBoundaries || this.actionBoundaries.width === 0) {
      this.actionBoundaries = getActionBoundaries(points);
    }

    element.style.width = this.actionBoundaries.width + "px";
    element.style.height = this.actionBoundaries.height + "px";
    element.style.zIndex = "2";

    this.size = this.actionBoundaries.width * this.actionBoundaries.height;

    for (ptIndex = 1; ptIndex < points.length; ptIndex = ptIndex + 1) {
      var point2 = points[ptIndex],
        lineElem = document.createElement("hr"),
        xdiff = point2.x - point1.x,
        ydiff = point2.y - point1.y,
        length = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
        angle = Math.atan2((point2.y - point1.y), (point2.x - point1.x)) * 180 / Math.PI;
      lineElem.style.borderColor = this.color;
      lineElem.style.borderWidth = this.lineWidth + "px";
      lineElem.style.width = length * 100 / (this.actionBoundaries.width) + "%";
      lineElem.style.height = "0px";
      lineElem.style.position = "absolute";
      lineElem.style.top = (point1.y - this.actionBoundaries.y) * 100 / this.actionBoundaries.height + "%";
      lineElem.style.left = (point1.x - this.actionBoundaries.x) * 100 / this.actionBoundaries.width + "%";
      lineElem.style.margin = "0px";
      lineElem.style.transformOrigin = "left center";
      lineElem.setAttribute("noshade", "");
      lineElem.style.transform = "rotate(" + angle + "deg)";

      element.appendChild(lineElem);
      point1 = points[ptIndex];
    }

    //set reference to this.element
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.actionBoundaries.y + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px";
    this.element.style.left = this.actionBoundaries.x + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px";

    baseElement.appendChild(this.element);

    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.freeformAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.freeformAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
    // this.areaPerimeterFlag = areaPerimeterFlag;
    //this.bookmarkFlag = bookmarkFlag;
  };

  ann.freeformAction.prototype.onCancel = function () {
    this.deleteAnnotation();
  };

  ann.freeformAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  function updateActionBoundaries(point, boundaries) {
    if (point.x < boundaries.x) {
      boundaries.width += boundaries.x - point.x;
      boundaries.x = point.x;
    } else if (point.x > (boundaries.x + boundaries.width)) {
      boundaries.width = point.x - boundaries.x;
    }
    if (point.y < boundaries.y) {
      boundaries.height += boundaries.y - point.y;
      boundaries.y = point.y;
    } else if (point.y > (boundaries.y + boundaries.height)) {
      boundaries.height = point.y - boundaries.y;
    }
  }

  function getActionBoundaries(points) {
    var pointIndex,
      actionBoundaries = {
        x: points[0].x,
        y: points[0].y,
        width: 0,
        height: 0
      },
      x2 = points[0].x,
      y2 = points[0].y;

    for (pointIndex = 1; pointIndex < points.length; pointIndex = pointIndex + 1) {
      //To Do: See if there is any optimum way of doing this
      var point = points[pointIndex];
      if (point.x < actionBoundaries.x) {
        actionBoundaries.x = point.x;
      } else if (point.x > x2) {
        x2 = point.x;
      }
      actionBoundaries.width = x2 - actionBoundaries.x;
      if (point.y < actionBoundaries.y) {
        actionBoundaries.y = point.y;
      } else if (point.y > y2) {
        y2 = point.y;
      }
      actionBoundaries.height = y2 - actionBoundaries.y;
    }
    return actionBoundaries;
  }

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  function addClickAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("click", function (event) {
      implementedAction.fillColor(getFillColor());
    });
  }

  function getSlope(p1, p2) {
    return (p2.y - p1.y) / (p2.x - p1.x);
  }

  function zipPoints(points, tolerance) {
    tolerance = tolerance || 1;
    var zippedPointsSet = [points[0], points[1]],
      lastPoint = points[0],
      lastSlope = getSlope(points[1], lastPoint),
      origDatasetLen = points.length,
      ptDist = 3;

    for (var i = 2; i < origDatasetLen; i++) {
      var currentPt = points[i];
      //check for nearness
      if (Math.abs(currentPt.x - lastPoint.x) < ptDist && Math.abs(currentPt.y - lastPoint.y) < ptDist) {
        continue;
      } else {
        //check for slope
        var currentSlope = getSlope(currentPt, lastPoint);
        if (Math.abs(currentSlope - lastSlope) < tolerance) {
          zippedPointsSet.pop();
        }
        zippedPointsSet.push(currentPt);
        lastPoint = currentPt;
        lastSlope = currentSlope;
      }
    }
    return zippedPointsSet;
  }

  ann.closedFreeformAction = function (annotationData, actionBoundaries, isFilled = false) {
    this.type = isFilled ? "FilledFreeform" : "Freeform";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.areaPerimeterFlag = false;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.points = [];
    this.comment = "";
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.actionBoundaries = actionBoundaries || (this.points && this.points.length > 0 ? getActionBoundaries(this.points) : {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    });
    this.element = null;
    this.fillElement = null;
    this.tempLayer = null;
  };

  ann.closedFreeformAction.prototype.actionChangeBehavior = function (baseElement, point) {
    /* if(this.points.length > 2){
      let prev_yPointDiff = (this.points[this.points.length - 1].y - this.points[this.points.length - 2].y);
      let prev_xPointDiff = (this.points[this.points.length - 1].x - this.points[this.points.length - 2].x);
      let prevDegree = Math.atan2(prev_yPointDiff, prev_xPointDiff) * 360 / Math.PI;

      let curr_yPointDiff = (point.y - this.points[this.points.length - 1].y);
      let curr_xPointDiff = (point.x - this.points[this.points.length - 1].x);
      let currentDegree = Math.atan2(curr_yPointDiff, curr_xPointDiff) * 360 / Math.PI;

      let redundantDegree = 10;

      if ((currentDegree < (prevDegree - redundantDegree)) || (currentDegree > (prevDegree + redundantDegree))) {
        this.points.pop();
      }
    }     */

    var context = baseElement.getContext("2d");
    // tempLayerContext = this.tempLayer ? this.tempLayer.getContext("2d") : null;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    if (this.marker) {
      this.color = "#000";
    }

    console.log("test: " + actionCompleted);
    if (this.type == "FilledFreeform") {
      context.fillStyle = this.color + this.filledOpacityLevel;
    }
    context.strokeStyle = this.color;
    context.lineWidth = this.lineWidth;
    /* if (tempLayerContext) {
      tempLayerContext.strokeStyle = this.color;
      // if (this.type == "FilledFreeform") {
      //   tempLayerContext.fillStyle = this.color + '11';
      // } 
      tempLayerContext.lineWidth = context.lineWidth;
    } */
    if (actionCompleted) {
      this.tempLayer = baseElement.cloneNode(true);
      this.tempLayer.style.zIndex = "2";
      this.tempLayer.style.position = "absolute";
      baseElement.parentNode.appendChild(this.tempLayer);

      this.actionBoundaries.x = point.x;
      this.actionBoundaries.y = point.y;
      context.beginPath();
      context.moveTo(point.x, point.y);
      this.points.push(point);
      actionCompleted = false;
    } else {
      context.beginPath();
      context.moveTo(this.points[0].x, this.points[0].y);
      for (let i = 0; i < this.points.length; i++) {
        context.lineTo(this.points[i].x, this.points[i].y);
      }
      context.lineTo(point.x, point.y);
      context.lineTo(this.points[0].x, this.points[0].y);
      if (this.type == "FilledFreeform") {
        context.fill();
      }
      context.stroke();
      this.points.push(point);
      updateActionBoundaries(point, this.actionBoundaries);

      /* tempLayerContext.clearRect(0, 0, context.canvas.width, context.canvas.height);
      tempLayerContext.beginPath();
      tempLayerContext.moveTo(this.points[0].x, this.points[0].y);
      tempLayerContext.lineTo(point.x, point.y);
      // if (this.type == "FilledFreeform") {
      //   tempLayerContext.fill();
      // }
      tempLayerContext.stroke(); */
    }
  };


  ann.closedFreeformAction.prototype.reomveRedundantCoOrds = function (redundantDegree) {
    var newPointsArr = [this.points[0]];
    var prevDegree;
    console.log("prev Points Arr length: " + this.points.length);
    for (let a = 1; a < this.points.length; a++) {
      let yPointDiff = (this.points[a].y - newPointsArr[newPointsArr.length - 1].y);
      let xPointDiff = (this.points[a].x - newPointsArr[newPointsArr.length - 1].x);
      let currentDegree = Math.atan2(yPointDiff, xPointDiff) * 360 / Math.PI;
      if (!prevDegree) {
        prevDegree = currentDegree;
      }
      if ((currentDegree < (prevDegree - redundantDegree)) || (currentDegree > (prevDegree + redundantDegree))) {
        prevDegree = currentDegree;
        newPointsArr.push(this.points[a]);
      } else if (a == (this.points.length - 1)) {
        newPointsArr.push(this.points[a]);
      }
    }

    this.points = newPointsArr;
    console.log("curr Points Arr length: " + this.points.length);
  }

  ann.closedFreeformAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    this.points.push(point);
    this.points.push(this.points[0]);

    this.reomveRedundantCoOrds(5);

    var context = baseElement.getContext("2d");
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.beginPath();
    context.moveTo(this.points[0].x, this.points[0].y);
    for (let i = 0; i < this.points.length; i++) {
      context.lineTo(this.points[i].x, this.points[i].y);
    }
    // context.lineTo(point.x, point.y);
    // context.lineTo(this.points[0].x, this.points[0].y);
    if (this.type == "FilledFreeform") {
      context.fill();
    }
    context.stroke();
    context.closePath();
    for (let x = 0; x < this.points.length; x++) {
      this.points[x].y = this.points[x].y - (this.lineWidth / 2);
    }
    // point.y = point.y - (this.lineWidth / 2);
    updateActionBoundaries(point, this.actionBoundaries);

    this.tempLayer.parentNode.removeChild(this.tempLayer);
    this.area = {
      "value": '0.0',
      "unit": "mm"
    };
    this.perimeter = {
      "value": '0.0',
      "unit": "mm"
    };

    this.showAnnotation(this.points, baseElement.parentNode);

    actionCompleted = true;
  };

  ann.closedFreeformAction.prototype.showAnnotation = function (points, baseElement) {
    var element = document.createElement("div"),
      point1 = points[0],
      ptIndex;

    element.setAttribute("id", "closed-freeform-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    points = this.points; // = zipPoints(points, 0.15);

    //if this.actionBoundaries not there get them from points and set them
    if (!this.actionBoundaries || this.actionBoundaries.width === 0) {
      this.actionBoundaries = getActionBoundaries(points);
    }

    element.style.width = this.actionBoundaries.width + "px";
    element.style.height = this.actionBoundaries.height + "px";
    element.style.zIndex = "2";

    this.size = this.actionBoundaries.width * this.actionBoundaries.height;

    for (ptIndex = 1; ptIndex < points.length; ptIndex = ptIndex + 1) {
      var point2 = points[ptIndex],
        lineElem = document.createElement("hr"),
        xdiff = point2.x - point1.x,
        ydiff = point2.y - point1.y,
        length = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
        angle = Math.atan2((point2.y - point1.y), (point2.x - point1.x)) * 180 / Math.PI,
        scaleParam = "";
      lineElem.style.borderColor = this.color;
      lineElem.style.borderWidth = this.lineWidth + "px";
      lineElem.style.width = length * 100 / (this.actionBoundaries.width) + "%";
      lineElem.style.height = "0px";
      lineElem.style.position = "absolute";
      lineElem.style.top = (point1.y - this.actionBoundaries.y) * 100 / this.actionBoundaries.height + "%";
      lineElem.style.left = (point1.x - this.actionBoundaries.x) * 100 / this.actionBoundaries.width + "%";
      lineElem.style.margin = "0px";
      lineElem.style.transformOrigin = "left center";
      lineElem.setAttribute("noshade", "");
      lineElem.style.transform = "rotate(" + angle + "deg)" + scaleParam;
      element.appendChild(lineElem);
      point1 = points[ptIndex];
    }

    //set reference to this.element
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.actionBoundaries.y + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px";
    this.element.style.left = this.actionBoundaries.x + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px";

    baseElement.appendChild(this.element);

    if (!this.marker) {
      addHighlightAction(this, baseElement.firstElementChild);
    } else {
      addClickAction(this, baseElement.firstElementChild);
    }
  };

  ann.closedFreeformAction.prototype.fillColor = function (color) {
    this.fillColor = color;
    if (!this.fillElement) {
      var canvasElem = document.createElement("canvas");
      canvasElem.width = this.actionBoundaries.width;
      canvasElem.height = this.actionBoundaries.height;
      canvasElem.style.width = this.actionBoundaries.width + "px";
      canvasElem.style.height = this.actionBoundaries.height + "px";

      var ctx = canvasElem.getContext("2d");
      ctx.beginPath();

      var pts = this.points,
        offsetPt = {
          x: this.actionBoundaries.x,
          y: this.actionBoundaries.y
        },
        ptIndex;
      ctx.moveTo(pts[0].x - offsetPt.x, pts[0].y - offsetPt.y);
      for (ptIndex = 1; ptIndex < pts.length; ptIndex = ptIndex + 1) {
        var point = pts[ptIndex];
        ctx.lineTo(point.x - offsetPt.x, point.y - offsetPt.y);
      }
      ctx.lineTo(pts[0].x - offsetPt.x, pts[0].y - offsetPt.y);
      ctx.stroke();
      ctx.closePath();
      ctx.globalAlpha = 0.5;
      ctx.fillStyle = color;
      ctx.fill();
      this.element.appendChild(canvasElem);
      canvasElem.style.width = "100%";
      canvasElem.style.height = "100%";
      this.fillElement = canvasElem;
    } else {
      var ctx = this.fillElement.getContext("2d");
      ctx.fillStyle = color;
      ctx.globalAlpha = 0.5;
      ctx.fill;
    }
  };

  ann.closedFreeformAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.closedFreeformAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.closedFreeformAction.prototype.onCancel = function () {
    this.deleteAnnotation();
  };

  ann.closedFreeformAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  ann.rectangularAction = function (annotationData) {
    this.type = "Rectangular";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.areaPerimeterFlag = annotationData.areaPerimeterFlag == undefined ? true : annotationData.areaPerimeterFlag;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.element = null;
  };

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.rectangularAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.lineWidth = this.thickness;
    if (actionCompleted) {
      context.beginPath();
      this.x = point.x;
      this.y = point.y;
      this.width = 1;
      this.height = 1;
      actionCompleted = false;
    } else {
      this.width = point.x - this.x;
      this.height = point.y - this.y;
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.beginPath();

      context.rect(this.x, this.y, this.width, this.height);
      context.stroke();
    }
  };

  ann.rectangularAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    let leftThreshold = 0, topThreshold = 0, widthThreshold = 0, heightThreshold = 0;
    if (this.width < 0) {
      leftThreshold = (this.thickness / 2);
      widthThreshold = -this.thickness;
    } else {
      leftThreshold = -(this.thickness / 2);
      widthThreshold = this.thickness;
    }

    if (this.height < 0) {
      topThreshold = (this.thickness / 2);
      heightThreshold = -this.thickness;
    } else {
      topThreshold = -(this.thickness / 2);
      heightThreshold = this.thickness;
    }

    this.x = this.x + leftThreshold;
    this.y = this.y + topThreshold;
    this.width = this.width + widthThreshold;
    this.height = this.height + heightThreshold;

    var context = baseElement.getContext("2d");
    context.closePath();
    this.getArea();
    this.getPerimeter();
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    this.showAnnotation(baseElement.parentNode);

    this.points = [];

    actionCompleted = true;
  };

  ann.rectangularAction.prototype.showAnnotation = function (baseElement) {
    var element = document.createElement("div");
    element.setAttribute("id", "rectangular-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    //set height and width of the canvas element
    element.style.width = (Math.abs(this.width)) + "px";
    element.style.height = (Math.abs(this.height)) + "px";
    element.style.zIndex = "2";
    element.style.border = this.thickness + "px solid " + this.color;

    this.size = Math.abs(this.width) * Math.abs(this.height);

    //set reference to this.freeformElement
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.height > 0 ? this.y + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px" : this.y + (this.canvasInfo ? this.canvasInfo.yPos : 0) + this.height + "px";
    this.element.style.left = this.width > 0 ? this.x + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px" : this.x + (this.canvasInfo ? this.canvasInfo.xPos : 0) + this.width + "px";

    baseElement.appendChild(this.element);
    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.rectangularAction.prototype.deleteAnnotation = function () {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.rectangularAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.rectangularAction.prototype.getHighlightPosition = function (element) {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

  ann.rectangularAction.prototype.getArea = function () {
    var area = (this.width * this.pixelToNanometer) * (this.height * this.pixelToNanometer);
    var unit = '';

    if (area < Math.pow(10, 6)) {
      area = area.toFixed(2);
      unit = "nm&sup2;";
    } else if (area < Math.pow(10, 12)) {
      area = (area / 1000000).toFixed(2);
      unit = "μm&sup2;";
    } else if (area < Math.pow(10, 18)) {
      area = (area / Math.pow(10, 12)).toFixed(2);
      unit = "mm&sup2;";
    }
    this.area = {
      "value": area,
      "unit": unit
    };
  }

  ann.rectangularAction.prototype.getPerimeter = function () {
    var perimeter = 2 * ((this.width * this.pixelToNanometer) + (this.height * this.pixelToNanometer));
    var unit = '';
    if (perimeter < Math.pow(10, 3)) {
      perimeter = perimeter.toFixed(2);
      unit = "nm";
    } else if (perimeter < Math.pow(10, 6)) {
      perimeter = (perimeter / 1000).toFixed(2);
      unit = "μm";
    } else if (perimeter < Math.pow(10, 9)) {
      perimeter = (perimeter / Math.pow(10, 6)).toFixed(2);
      unit = "mm";
    }
    this.perimeter = {
      "value": perimeter,
      "unit": unit
    };
  }

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  ann.circularAction = function (annotationData) {
    this.type = "Circular";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.slideId = annotationData.slideId;
    this.areaPerimeterFlag = false;
    this.meetId = annotationData.meetId;
    this.areaPerimeterFlag = annotationData.areaPerimeterFlag == undefined ? true : annotationData.areaPerimeterFlag;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.isSplitView = annotationData.isSplitView;
    this.annotationData = annotationData;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.element = null;
  };

  function calculateDistance(point1, point2) {
    return Math.sqrt((point2.x - point1.x) * (point2.x - point1.x) + (point2.y - point1.y) * (point2.y - point1.y));
  }

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.circularAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.lineWidth = this.thickness;
    if (actionCompleted) {
      context.beginPath();
      this.centerX = point.x;
      this.centerY = point.y;
      this.radius = 1;
      actionCompleted = false;
    } else {
      this.radius = this.radius ? this.radius : 1;
      if (!((this.centerX < this.radius) || (this.centerY < this.radius) || ((this.centerY + this.radius) > baseElement.height) || ((this.centerX + this.radius) > baseElement.width))) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.beginPath();
        this.radius = calculateDistance(point, {
          x: this.centerX,
          y: this.centerY
        });

        if (this.radius > this.centerX) {
          this.radius = (this.centerX);
        }
        if (this.radius > this.centerY) {
          this.radius = (this.centerY);
        }
        if ((this.centerX + this.radius) > baseElement.width) {
          this.radius = (baseElement.width - this.centerX);
        }
        if ((this.centerY + this.radius) > baseElement.height) {
          this.radius = (baseElement.height - this.centerY);
        }
        context.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI);
        context.stroke();
      }
    }
  };

  ann.circularAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    this.radius = (Math.abs(this.radius) + (this.lineWidth / 2)) > 0 ? (Math.abs(this.radius) + (this.lineWidth / 2)) : 1;

    var context = baseElement.getContext("2d");
    context.closePath();
    this.getArea();
    this.getPerimeter();
    this.showAnnotation(baseElement.parentNode);
    actionCompleted = true;
  };

  ann.circularAction.prototype.showAnnotation = function (baseElement) {
    var element = document.createElement("div");
    element.setAttribute("id", "circular-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    //set height and width of the canvas element
    element.style.width = this.radius * 2 + "px";
    element.style.height = this.radius * 2 + "px";
    element.style.zIndex = "2";
    element.style.border = this.thickness + "px solid " + this.color;
    element.style.borderRadius = "50%";

    this.size = this.radius * 4;

    //set reference to this.freeformElement
    this.element = element;

    this.points = [];

    this.element.style.position = "absolute";
    this.element.style.top = this.centerY + (this.canvasInfo ? this.canvasInfo.yPos : 0) - this.radius + "px";
    this.element.style.left = this.centerX + (this.canvasInfo ? this.canvasInfo.xPos : 0) - this.radius + "px";

    baseElement.appendChild(this.element);
    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.circularAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.circularAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.circularAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

  ann.circularAction.prototype.getArea = function () {
    var area = Math.PI * Math.pow((this.radius * this.pixelToNanometer), 2);
    var unit = '';

    if (area < Math.pow(10, 6)) {
      area = area.toFixed(2);
      unit = "nm&sup2;";
    } else if (area < Math.pow(10, 12)) {
      area = (area / 1000000).toFixed(2);
      unit = "μm&sup2;";
    } else if (area < Math.pow(10, 18)) {
      area = (area / Math.pow(10, 12)).toFixed(2);
      unit = "mm&sup2;";
    }
    this.area = {
      "value": area,
      "unit": unit
    };
  }

  ann.circularAction.prototype.getPerimeter = function () {
    var perimeter = 2 * Math.PI * (this.radius * this.pixelToNanometer);

    var unit = '';
    if (perimeter < Math.pow(10, 3)) {
      perimeter = perimeter.toFixed(2);
      unit = "nm";
    } else if (perimeter < Math.pow(10, 6)) {
      perimeter = (perimeter / 1000).toFixed(2);
      unit = "μm";
    } else if (perimeter < Math.pow(10, 9)) {
      perimeter = (perimeter / Math.pow(10, 6)).toFixed(2);
      unit = "mm";
    }
    this.perimeter = {
      "value": perimeter,
      "unit": unit
    };
  }

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  ann.rulerAction = function (annotationData) {
    this.type = "Ruler";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.thickness = annotationData.thickness ? annotationData.thickness : 2;
    this.areaPerimeterFlag = false;
    this.areaPerimeterFlag = false;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.textColor = annotationData.colour ? annotationData.colour : "#000000";
    this.textSize = "18px";
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.element = null;
  };

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      // var position = implementedAction.getHighlightPosition();
      var position = {
        x: event.clientX,
        y: event.clientY
      };

      implementedAction.element.childNodes[0].style.outline = "#737373 solid";
      implementedAction.element.childNodes[0].style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.rulerAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.font = this.textSize + " Arial";
    context.lineWidth = this.thickness;
    if (actionCompleted) {
      context.beginPath();
      this.x1 = point.x;
      this.y1 = point.y;
      this.x2 = point.x + 1;
      this.y2 = point.y + 1;
      actionCompleted = false;
    } else {
      this.x2 = point.x;
      this.y2 = point.y;
      context.globalCompositeOperation = 'overlay';
      if (!((this.x2 < 0) || (this.y2 < 0) || ((this.y2) > baseElement.height) || ((this.x2) > baseElement.width))) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.beginPath();

        const endLen = 10; // length of end lines

        var px = this.y1 - this.y2; // as vector at 90 deg to the line
        var py = this.x2 - this.x1;
        const len = endLen / Math.hypot(px, py);
        px *= len;  // make leng 10 pixels
        py *= len;

        // draw line the start cap and end cap.
        // ctx.beginPath();

        context.lineTo(this.x1, this.y1);   // the line start
        context.lineTo(this.x2, this.y2);
        context.moveTo(this.x1 + px, this.y1 + py); // the start perp line
        context.lineTo(this.x1 - px, this.y1 - py);
        context.moveTo(this.x2 + px, this.y2 + py); // the end perp line
        context.lineTo(this.x2 - px, this.y2 - py);
        if (this.pixelToNanometer > 0) {
          var realDistance = this.getRealDistance(),
            distLabelX = (this.x2 + this.x1) / 2,
            distLabelY = (this.y2 + this.y1) / 2;

          context.fillStyle = this.textColor;
          context.fillText(realDistance, distLabelX, distLabelY);
          this.realDistance = realDistance;
        }
        context.stroke();
        /* var ang = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)) * 180 / Math.PI;
        let topThreshold = 0, leftThreshold = 0;

        console.log("angle: " + ang);
        if (ang > 45 && ang < 135) {
          leftThreshold = -(this.thickness / 2);
        } else if (ang < -45 && ang > -135) {
          leftThreshold = (this.thickness / 2);
        }

        if (ang < 45 && ang > -45) {
          topThreshold = (this.thickness / 2);
        } else if (ang < -135 || ang > 135) {
          topThreshold = -(this.thickness / 2);
        }

        context.moveTo(this.x1 + leftThreshold, this.y1 + topThreshold);
        context.lineTo(this.x2 + leftThreshold, this.y2 + topThreshold);
        context.moveTo(this.x1, this.y1);
        context.lineTo(this.x2, this.y2);
        if (this.pixelToNanometer > 0) {
          var realDistance = this.getRealDistance(),
            distLabelX = (this.x2 + this.x1) / 2,
            distLabelY = (this.y2 + this.y1) / 2;

          context.fillStyle = this.textColor;
          context.fillText(realDistance, distLabelX, distLabelY);
          this.realDistance = realDistance;
        }
        context.stroke(); */
      }
    }
  };

  ann.rulerAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");

    this.x2 = point.x;
    this.y2 = point.y;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    if (this.x2 < 2) {
      this.x2 = 2;
    }
    if (this.y2 < 0) {
      this.y2 = 0;
    }
    if ((this.x2) > baseElement.width) {
      this.x2 = (baseElement.width);
    }
    if ((this.Y2) > baseElement.height) {
      this.y2 = (baseElement.height);
    }

    var ang = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)) * 180 / Math.PI;
    let topThreshold = 0, leftThreshold = 0;

    console.log("angle: " + ang);
    if (ang > 45 && ang < 135) {
      leftThreshold = -(this.thickness / 2);
    } else if (ang < -45 && ang > -135) {
      leftThreshold = (this.thickness / 2);
    }

    if (ang < 45 && ang > -45) {
      topThreshold = (this.thickness / 2);
    } else if (ang < -135 || ang > 135) {
      topThreshold = -(this.thickness / 2);
    }

    this.x1 = this.x1 - leftThreshold;
    this.y1 = this.y1 - topThreshold;

    this.x2 = this.x2 - leftThreshold;
    this.y2 = this.y2 - topThreshold;

    this.area = {
      "value": '0.0',
      "unit": "mm"
    };
    this.perimeter = {
      "value": '0.0',
      "unit": "mm"
    };

    this.showAnnotation(baseElement.parentNode);

    this.points = [];

    actionCompleted = true;
  };

  ann.rulerAction.prototype.showAnnotation = function (baseElement) {
    var element = document.createElement("div");
    element.setAttribute("id", "ruler-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';
    var xdiff = Math.abs(this.x2 - this.x1);
    var ydiff = Math.abs(this.y2 - this.y1);
    var length = Math.sqrt(xdiff * xdiff + ydiff * ydiff);
    this.length = length;
    element.style.width = length + "px";
    element.style.height = 0;

    var lineElem = document.createElement("div");
    lineElem.style.borderColor = this.color;
    lineElem.style.borderWidth = this.thickness + "px";
    lineElem.style.width = "100%";
    lineElem.style.backgroundColor = this.color;
    lineElem.style.height = this.thickness + "px";
    lineElem.style.position = "absolute";
    lineElem.style.margin = "0px";
    lineElem.setAttribute("noshade", "");
    var angle = Math.atan((this.y2 - this.y1) / (this.x2 - this.x1)) * 180 / Math.PI;
    if ((this.y2 == this.y1) && this.x2 < this.x1) {
      angle = 180;
    }
    if ((this.x2 < this.x1 && this.y2 > this.y1) || (this.x2 < this.x1 && this.y2 < this.y1)) {
      angle = 180 + angle;
    }
    this.angle = angle;
    lineElem.style.transform = "rotate(" + angle + "deg)";
    lineElem.style.transformOrigin = "left top";

    this.size = Math.abs(xdiff) * Math.abs(ydiff);
    element.appendChild(lineElem);
    element.style.height = 0;

    //set reference to this.freeformElement
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.y1 + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px";
    this.element.style.left = this.x1 + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px";

    baseElement.appendChild(this.element);

    this.addMeasurementLabel();

    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.rulerAction.prototype.getRealDistance = function () {
    var xdiff = this.x2 - this.x1,
      ydiff = this.y2 - this.y1,
      canvasLength = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
      realDist = canvasLength * this.pixelToNanometer;

    if (realDist < Math.pow(10, 3)) {
      realDist = realDist.toFixed(2) + " nm";
    } else if (realDist < Math.pow(10, 6)) {
      realDist = (realDist / 1000).toFixed(2) + " μm";
    } else if (realDist < Math.pow(10, 9)) {
      realDist = (realDist / Math.pow(10, 6)).toFixed(2) + " mm";
    }

    return realDist;
  };

  ann.rulerAction.prototype.addMeasurementLabel = function () {
    var mLabel = document.createElement("div"),
      angle = this.angle,
      xDirection = 1,
      yDirection = 1,
      xdiff = this.x2 - this.x1,
      height = Math.sqrt(this.length * this.length - xdiff * xdiff);
    mLabel.style.position = "absolute";
    if (this.x2 < this.x1) {
      angle = this.angle - 180;
      xDirection = -1;
    }
    if (this.y2 < this.y1) {
      yDirection = -1;
    }
    mLabel.innerHTML = this.realDistance ? this.realDistance : this.getRealDistance();
    mLabel.style.left = (this.x2 - this.x1 - 30) / 2 / this.length * 100 + "%";
    mLabel.style.top = (this.y2 - this.y1) / 2 / height * 100 + "%";
    mLabel.style.fontSize = this.textSize;
    mLabel.style.zIndex = "99";
    mLabel.style.color = this.textColor;
    mLabel.style.padding = "2px";

    this.element.appendChild(mLabel);
  };

  ann.rulerAction.prototype.setConverter = function (conversionRatio) {
    this.pixelToNanometer = conversionRatio;
  };

  ann.rulerAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.rulerAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.rulerAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  ann.arrowAction = function (annotationData) {
    this.type = "Arrow";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.thickness = annotationData.thickness ? annotationData.thickness : 2;
    this.areaPerimeterFlag = false;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.element = null;
  };

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      // var position = implementedAction.getHighlightPosition();
      var position = {
        x: event.clientX,
        y: event.clientY
      };

      implementedAction.element.childNodes[0].childNodes[0].style.outline = "#737373 solid";
      implementedAction.element.childNodes[0].childNodes[0].style.outlineOffset = (parseFloat(implementedAction.element.childNodes[0].getAttribute("outlineInfo")) + 3) + "px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.arrowAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.lineWidth = this.thickness;
    if (actionCompleted) {
      context.beginPath();
      this.x1 = point.x;
      this.y1 = point.y;
      this.x2 = point.x + 1;
      this.y2 = point.y + 1;
      actionCompleted = false;
    } else {
      this.x2 = point.x;
      this.y2 = point.y;
      if (this.x2 < 2) {
        this.x2 = 2;
      }
      if (this.y2 < 0) {
        this.y2 = 0;
      }
      if ((this.x2) > baseElement.width) {
        this.x2 = (baseElement.width);
      }
      if ((this.Y2) > baseElement.height) {
        this.y2 = (baseElement.height);
      }
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.beginPath();

      let topThreshold = 0, leftThreshold = 0;
      /* var ang = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)) * 180 / Math.PI;

      console.log("angle: " + ang);
      if (ang > 45 && ang < 135) {
        leftThreshold = -(this.thickness / 2);
      } else if (ang < -45 && ang > -135) {
        leftThreshold = (this.thickness / 2);
      }

      if (ang < 45 && ang > -45) {
        topThreshold = (this.thickness / 2);
      } else if (ang < -135 || ang > 135) {
        topThreshold = -(this.thickness / 2);
      } */

      let fromx = (this.x1 + leftThreshold), fromy = (this.y1 + topThreshold), tox = (this.x2 + leftThreshold), toy = (this.y2 + topThreshold);
      const dx = tox - fromx;
      const dy = toy - fromy;
      const headlen = Math.sqrt(dx * dx + dy * dy) * 0.1; // length of head in pixels
      this.headLength = headlen;
      // const headlen = (this.thickness * 3) > 20 ? (this.thickness * 3) : 20; // length of head in pixels
      const angle = Math.atan2(dy, dx);
      const headAngle = Math.PI / 4;
      context.beginPath();
      context.moveTo(fromx, fromy);
      context.lineTo(tox, toy);
      context.stroke();
      context.beginPath();
      console.log("leftThreshold: " + leftThreshold + ", topThreshold: " + topThreshold);
      context.moveTo(tox - headlen * Math.cos(angle - headAngle), toy - (headlen * Math.sin(angle - headAngle)));
      context.lineTo(tox, toy);
      context.lineTo(tox - headlen * Math.cos(angle + headAngle), toy - (headlen * Math.sin(angle + headAngle)));
      context.stroke();

      /* //To Do: draw arrow head
      var arrowAngle = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)),
        headLength = (this.thickness * 3) > 20 ? (this.thickness * 3) : 20,
        headAngle = 45 * Math.PI / 180,
        x3 = this.x2 - headLength * Math.cos(arrowAngle - headAngle),
        y3 = this.y2 - headLength * Math.sin(arrowAngle - headAngle),
        x4 = this.x2 - headLength * Math.cos(arrowAngle + headAngle),
        y4 = this.y2 - headLength * Math.sin(arrowAngle + headAngle);
      context.lineTo(x3, y3);
      context.stroke();
      context.beginPath();
      context.lineTo(this.x2, this.y2);
      context.lineTo(x4, y4);
      //End: draw arrow head */

      context.stroke();
    }
  };

  ann.arrowAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    var ang = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)) * 180 / Math.PI;
    let topThreshold = 0, leftThreshold = 0;

    // console.log("angle: " + ang);
    if (ang > 45 && ang < 135) {
      leftThreshold = -(this.thickness / 2);
    } else if (ang < -45 && ang > -135) {
      leftThreshold = (this.thickness / 2);
    }

    if (ang < 45 && ang > -45) {
      topThreshold = (this.thickness / 2);
    } else if (ang < -135 || ang > 135) {
      topThreshold = -(this.thickness / 2);
    }

    this.x1 = (this.x1 - leftThreshold);
    this.y1 = (this.y1 - topThreshold);
    this.x2 = (this.x2 - leftThreshold);
    this.y2 = (this.y2 - topThreshold);


    var context = baseElement.getContext("2d");
    context.closePath();
    this.area = {
      "value": '0.0',
      "unit": "mm"
    };
    this.perimeter = {
      "value": '0.0',
      "unit": "mm"
    };

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    this.showAnnotation(baseElement.parentNode);

    this.points = [];

    actionCompleted = true;
  };

  ann.arrowAction.prototype.showAnnotation = function (baseElement) {
    var element = document.createElement("div");
    element.setAttribute("id", "arrow-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';
    var xdiff = Math.abs(this.x2 - this.x1);
    var ydiff = Math.abs(this.y2 - this.y1);
    var length = Math.sqrt(xdiff * xdiff + ydiff * ydiff);
    this.length = length;
    element.style.width = length + "px";
    element.style.height = 0;

    var lineElemWrapper = document.createElement("div"),
      lineElem = document.createElement("div");
    lineElem.style.borderColor = this.color;
    lineElem.style.backgroundColor = this.color;
    lineElem.style.borderWidth = this.thickness + "px";
    lineElem.style.width = "100%";
    lineElem.style.height = this.thickness + "px";
    lineElem.style.position = "absolute";
    lineElem.style.margin = "0px";
    lineElem.setAttribute("noshade", "");
    // var angle = Math.atan((this.y2 - this.y1) / (this.x2 - this.x1)) * 180 / Math.PI;
    // if ((this.x2 < this.x1 && this.y2 > this.y1) || (this.x2 < this.x1 && this.y2 < this.y1)) {
    //   angle = 180 + angle;
    // }
    var angle = Math.atan2((this.y1 - this.y2), (this.x1 - this.x2)) * (180 / Math.PI);
    this.angle = angle - 180;
    lineElemWrapper.appendChild(lineElem);
    lineElemWrapper.style.transform = "rotate(" + this.angle + "deg)";
    lineElemWrapper.style.transformOrigin = "left top";
    lineElemWrapper.setAttribute("class", "rotateArrow");


    this.size = Math.abs(xdiff) * Math.abs(ydiff);
    element.appendChild(lineElemWrapper);
    element.style.height = 0;

    //arrow head element
    var headElem = document.createElement("i"),
      headElemStyles = headElem.style;
    headElemStyles.border = "solid " + this.color;
    headElemStyles.borderWidth = "0 " + this.thickness + "px " + this.thickness + "px 0";
    // headElemStyles.width = (this.thickness * 3) > 20 ? (this.thickness * 3) + (this.thickness / 2) + "px" : "20px";
    // headElemStyles.height = (this.thickness * 3) > 20 ? (this.thickness * 3) + (this.thickness / 2) + "px" : "20px";
    headElemStyles.width = this.headLength + 'px';
    headElemStyles.height = this.headLength + 'px';

    headElemStyles.display = "inline-block";
    // headElemStyles.padding = "-8px";
    headElemStyles.padding = -(this.headLength / 2) + "px";

    var ang = Math.atan2((this.y2 - this.y1), (this.x2 - this.x1)) * 180 / Math.PI;
    console.log("ang: " + ang);
    let rightThreshold = (this.thickness / 2);
    let marginTop = (-(this.headLength / 2) + this.thickness), marginRight = (15 - this.thickness);
    headElemStyles.marginTop = (marginTop - (this.thickness / 2)) + 'px';
    headElemStyles.marginRight = (marginRight) + 'px';
    headElemStyles.float = "right";
    headElemStyles.transform = "rotate(-45deg)";
    lineElemWrapper.appendChild(headElem);

    let outlineinfo = (Math.ceil(Math.sqrt(Math.pow(parseFloat(headElemStyles.width), 2) + Math.pow(parseFloat(headElemStyles.height), 2)))) / 2;
    lineElemWrapper.setAttribute("outlineInfo", outlineinfo);
    lineElemWrapper.style.height = (outlineinfo * 2) + "px";
    // lineElemWrapper.style.marginTop = "-" + (outlineinfo) + "px";
    // lineElemWrapper.style.paddingTop = (outlineinfo) + "px";

    //set reference to this.freeformElement
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.y1 + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px";
    this.element.style.left = this.x1 + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px";

    baseElement.appendChild(this.element);

    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.arrowAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.arrowAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.arrowAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  ann.ellipticalAction = function (annotationData) {
    this.type = "Ellipse";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.areaPerimeterFlag = annotationData.areaPerimeterFlag == undefined ? true : annotationData.areaPerimeterFlag;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.element = null;
  };

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.ellipticalAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.lineWidth = this.thickness;
    if (actionCompleted) {
      context.beginPath();
      this.centerX = point.x;
      this.centerY = point.y;
      this.maxDiameter = 1;
      this.maxDiameter = 1;
      actionCompleted = false;
    } else {
      var maxDiameter = (point.x - this.centerX);
      var minDiameter = (point.y - this.centerY);
      if (!((maxDiameter > this.centerX) ||
        (minDiameter > this.centerY) ||
        ((Math.abs(maxDiameter) + this.centerX) > baseElement.width) ||
        ((Math.abs(minDiameter) + this.centerY) > baseElement.height))) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.beginPath();
        context.ellipse(this.centerX, this.centerY, Math.abs(maxDiameter), Math.abs(minDiameter), Math.PI, 0, 2 * Math.PI);
        /* let maxDiameterThreshold = (Math.abs(maxDiameter) - this.lineWidth) > 0 ? (Math.abs(maxDiameter) - this.lineWidth) : 1;
        let minDiameterThreshold = (Math.abs(minDiameter) - this.lineWidth) > 0 ? (Math.abs(minDiameter) - this.lineWidth) : 1;
        context.ellipse(this.centerX - (this.lineWidth), this.centerY - (this.lineWidth), maxDiameterThreshold, minDiameterThreshold, Math.PI, 0, 2 * Math.PI); */
        context.closePath();
        context.stroke();

        this.maxDiameter = maxDiameter * 2;
        this.minDiameter = minDiameter * 2;
      }
    }
  };

  ann.ellipticalAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    this.centerX = this.centerX + (this.lineWidth);
    this.centerY = this.centerY + (this.lineWidth);
    this.maxDiameter = (Math.abs(this.maxDiameter) + (this.lineWidth * 2)) > 0 ? (Math.abs(this.maxDiameter) + (this.lineWidth * 2)) : 1;
    this.minDiameter = (Math.abs(this.minDiameter) + (this.lineWidth * 2)) > 0 ? (Math.abs(this.minDiameter) + (this.lineWidth * 2)) : 1;

    var context = baseElement.getContext("2d");
    context.closePath();
    // context.clearRect(0, 0, baseElement.width, baseElement.height);
    this.getArea();
    this.getPerimeter();
    this.showAnnotation(baseElement.parentNode);

    actionCompleted = true;
  };

  ann.ellipticalAction.prototype.showAnnotation = function (baseElement) {
    var element = document.createElement("div");
    element.setAttribute("id", "ellipse-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    //set height and width of the canvas element
    element.style.width = this.maxDiameter > 0 ? this.maxDiameter - this.lineWidth + "px" : Math.abs(this.maxDiameter) - this.lineWidth + "px";
    element.style.height = this.minDiameter > 0 ? this.minDiameter - this.lineWidth + "px" : Math.abs(this.minDiameter) - this.lineWidth + "px";
    element.style.zIndex = "2";
    element.style.border = this.lineWidth + "px solid " + this.color;
    element.style.borderRadius = "50%";

    this.element = element;

    this.points = [];

    this.element.style.position = "absolute";

    this.element.style.top = this.minDiameter > 0 ? this.centerY + (this.canvasInfo ? this.canvasInfo.yPos : 0) - (this.minDiameter / 2) - this.lineWidth / 2 + "px" : this.centerY + (this.canvasInfo ? this.canvasInfo.yPos : 0) + (this.minDiameter / 2) - this.lineWidth / 2 + "px";
    this.element.style.left = this.maxDiameter > 0 ? this.centerX + (this.canvasInfo ? this.canvasInfo.xPos : 0) - (this.maxDiameter / 2) - this.lineWidth / 2 + "px" : this.centerX + (this.canvasInfo ? this.canvasInfo.xPos : 0) + (this.maxDiameter / 2) - this.lineWidth / 2 + "px";

    baseElement.appendChild(this.element);

    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.ellipticalAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
  };

  ann.ellipticalAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
  };

  ann.ellipticalAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0];
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

  ann.ellipticalAction.prototype.getArea = function () {
    var area = Math.PI * ((Math.abs(this.minDiameter) * this.pixelToNanometer) / 2) * ((Math.abs(this.maxDiameter) * this.pixelToNanometer) / 2);
    var unit = '';

    if (area < Math.pow(10, 6)) {
      area = area.toFixed(2);
      unit = "nm&sup2;";
    } else if (area < Math.pow(10, 12)) {
      area = (area / 1000000).toFixed(2);
      unit = "μm&sup2;";
    } else if (area < Math.pow(10, 18)) {
      area = (area / Math.pow(10, 12)).toFixed(2);
      unit = "mm&sup2;";
    }
    this.area = {
      "value": area,
      "unit": unit
    };
  }

  ann.ellipticalAction.prototype.getPerimeter = function () {
    var perimeter = 2 * Math.PI * Math.sqrt((Math.pow((this.minDiameter * this.pixelToNanometer), 2) + Math.pow((this.maxDiameter * this.pixelToNanometer), 2)) / 2);

    var unit = '';
    if (perimeter < Math.pow(10, 3)) {
      perimeter = perimeter.toFixed(2);
      unit = "nm";
    } else if (perimeter < Math.pow(10, 6)) {
      perimeter = (perimeter / 1000).toFixed(2);
      unit = "μm";
    } else if (perimeter < Math.pow(10, 9)) {
      perimeter = (perimeter / Math.pow(10, 6)).toFixed(2);
      unit = "mm";
    }
    this.perimeter = {
      "value": perimeter,
      "unit": unit
    };
  }

}(Annotation));

(function (ann) {
  "use strict";
  var actionCompleted = true;

  function updateActionBoundaries(point, boundaries) {
    if (point.x < boundaries.x) {
      boundaries.width += boundaries.x - point.x;
      boundaries.x = point.x;
    } else if (point.x > (boundaries.x + boundaries.width)) {
      boundaries.width = point.x - boundaries.x;
    }
    if (point.y < boundaries.y) {
      boundaries.height += boundaries.y - point.y;
      boundaries.y = point.y;
    } else if (point.y > (boundaries.y + boundaries.height)) {
      boundaries.height = point.y - boundaries.y;
    }
  }

  function getActionBoundaries(points) {
    var pointIndex,
      actionBoundaries = {
        x: points[0].x,
        y: points[0].y,
        width: 0,
        height: 0
      },
      x2 = points[0].x,
      y2 = points[0].y;

    for (pointIndex = 1; pointIndex < points.length; pointIndex = pointIndex + 1) {
      //To Do: See if there is any optimum way of doing this
      var point = points[pointIndex];
      if (point.x < actionBoundaries.x) {
        actionBoundaries.x = point.x;
      } else if (point.x > x2) {
        x2 = point.x;
      }
      actionBoundaries.width = x2 - actionBoundaries.x;
      if (point.y < actionBoundaries.y) {
        actionBoundaries.y = point.y;
      } else if (point.y > y2) {
        y2 = point.y;
      }
      actionBoundaries.height = y2 - actionBoundaries.y;
    }
    return actionBoundaries;
  }

  ann.angleAction = function (annotationData, actionBoundaries) {
    this.type = "Angle";
    this.color = annotationData.colour ? annotationData.colour : "#00ff00";
    this.textColor = annotationData.colour ? annotationData.colour : "#000000";
    this.textSize = "18px";
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.lineWidth = annotationData.thickness ? annotationData.thickness : 2;
    this.thickness = this.lineWidth;
    this.areaPerimeterFlag = false;
    this.bookmarkFlag = annotationData.bookmarkFlag == undefined ? false : annotationData.bookmarkFlag;
    this.points = [];
    this.comment = "";
    this.isSplitView = annotationData.isSplitView;
    if (!Object.hasOwnProperty("assign")) {
      for (var k in annotationData) {
        this[k] = annotationData[k];
      }
    } else {
      Object.assign(this, annotationData);
    }
    this.actionBoundaries = actionBoundaries || (this.points && this.points.length > 0 ? getActionBoundaries(this.points) : {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    });
    this.element = null;
  };

  function addHighlightAction(implementedAction, baseElement) {
    implementedAction.element.addEventListener("mouseenter", function (event) {
      var position = implementedAction.getHighlightPosition();
      implementedAction.element.style.outline = "#737373 solid";
      implementedAction.element.style.outlineOffset = "4px";
      ann.Editor("highlight", position, implementedAction, baseElement.parentNode);
    });
  }

  ann.angleAction.prototype.actionChangeBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.font = this.textSize + " Arial";
    context.lineWidth = this.lineWidth;
    if (actionCompleted) {
      this.actionBoundaries.x = point.x;
      this.actionBoundaries.y = point.y;
      context.beginPath();
      context.moveTo(point.x, point.y);
      this.points.push(point);
      actionCompleted = false;
    } else {
      if (this.points.length < 2) {
        context.globalCompositeOperation = 'overlay';
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.beginPath();
        context.moveTo(this.points[0].x, this.points[0].y);
        context.lineTo(point.x, point.y);
        context.stroke();
      } else {
        context.globalCompositeOperation = 'overlay';
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.beginPath();
        context.moveTo(this.points[1].x, this.points[1].y);
        context.lineTo(point.x, point.y);
        context.stroke();
        this.drawAngleSymbol(this.points[0], this.points[1], {
          x: point.x,
          y: point.y
        }, context);
      }
    }
  };

  ann.angleAction.prototype.actionCompleteBehavior = function (baseElement, point) {
    var context = baseElement.getContext("2d");
    context.strokeStyle = this.color;
    context.font = this.textSize + " Arial";
    context.lineWidth = this.lineWidth;
    if (this.points.length == 1) {
      if (point.x < 2) {
        point.x = 2;
      }
      if (point.y < 0) {
        point.y = 0;
      }
      if ((point.x) > baseElement.width) {
        point.x = (baseElement.width);
      }
      if ((point.y) > baseElement.height) {
        point.y = (baseElement.height);
      }
    }
    this.points.push(point);

    updateActionBoundaries(point, this.actionBoundaries);

    var len = this.points.length;

    if (len == 2) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      this.showAnnotation(this.points, baseElement.parentNode);
    } else if (len == 3) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      this.area = {
        "value": '0.0',
        "unit": "mm"
      };
      this.perimeter = {
        "value": '0.0',
        "unit": "mm"
      };
      this.showAnnotation(this.points, baseElement.parentNode);
      //this.points = [];
      actionCompleted = true;
    }
  };
  ann.angleAction.prototype.drawAngleSymbol = function (pt3, pt2, pt1, ctx) {
    var dx1 = pt1.x - pt2.x;
    var dy1 = pt1.y - pt2.y;
    var dx2 = pt3.x - pt2.x;
    var dy2 = pt3.y - pt2.y;
    var a1 = Math.atan2(dy1, dx1);
    var a2 = Math.atan2(dy2, dx2);
    var a = parseInt((a2 - a1) * 180 / Math.PI + 360) % 360;
    var angle = Math.atan2(dx1 * dy2 - dy1 * dx2, dx1 * dx2 + dy1 * dy2);
    if (angle < 0) {
      angle = angle * -1;
    }
    var degree_angle = angle * (180 / Math.PI);
    var clockwise = a > 180 ? true : false;
    // draw angleSymbol
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(pt2.x, pt2.y);
    ctx.arc(pt2.x, pt2.y, 20, a1, a2, clockwise);
    ctx.closePath();
    ctx.fillStyle = this.textColor;;
    ctx.globalAlpha = 0.25;
    ctx.fill();
    ctx.restore();
    ctx.fillStyle = this.textColor;;
    ctx.fillText(Math.round(degree_angle), pt2.x + 5, pt2.y);
  }
  ann.angleAction.prototype.showAnnotation = function (points, baseElement) {
    if (this.element) {
      // this.element.parentNode.removeChild(this.element);
      if (this.element && this.element.parentNode) {
        this.element.parentNode.removeChild(this.element);
      }

      if (this.element) {
        $(this.element).remove();
      }
    }
    var element = document.createElement("div"),
      point1 = points[0],
      ptIndex;

    //points = zipPoints(points, 0.15);

    element.setAttribute("id", "angle-annotation");
    element.setAttribute("class", "annotaion");
    this.id ? element.setAttribute("annoid", this.id) : '';

    //if this.actionBoundaries not there get them from points and set them
    if (!this.actionBoundaries || this.actionBoundaries.width === 0) {
      this.actionBoundaries = getActionBoundaries(points);
    }

    element.style.width = this.actionBoundaries.width + "px";
    element.style.height = this.actionBoundaries.height + "px";
    element.style.zIndex = "2";

    this.size = this.actionBoundaries.width * this.actionBoundaries.height;

    for (ptIndex = 1; ptIndex < points.length; ptIndex = ptIndex + 1) {
      var point2 = points[ptIndex],
        lineElem = document.createElement("hr"),
        xdiff = point2.x - point1.x,
        ydiff = point2.y - point1.y,
        length = Math.sqrt(xdiff * xdiff + ydiff * ydiff),
        angle = Math.atan2((point2.y - point1.y), (point2.x - point1.x)) * 180 / Math.PI;
      lineElem.style.borderColor = this.color;
      lineElem.style.borderWidth = this.lineWidth + "px";
      lineElem.style.borderRadius = this.lineWidth * 4 + "px";
      lineElem.style.width = length * 100 / (this.actionBoundaries.width) + "%";
      lineElem.style.height = "0px";
      lineElem.style.position = "absolute";
      lineElem.style.top = (point1.y - this.actionBoundaries.y - this.lineWidth / 2) * 100 / this.actionBoundaries.height + "%";
      lineElem.style.left = (point1.x - this.actionBoundaries.x) * 100 / this.actionBoundaries.width + "%";
      lineElem.style.margin = "0px";
      lineElem.style.transformOrigin = "left center";
      lineElem.setAttribute("noshade", "");
      lineElem.style.transform = "rotate(" + angle + "deg)";
      element.appendChild(lineElem);
      point1 = points[ptIndex];
    }

    //set reference to this.element
    this.element = element;

    this.element.style.position = "absolute";
    this.element.style.top = this.actionBoundaries.y + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px";
    this.element.style.left = this.actionBoundaries.x + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px";
    baseElement.appendChild(this.element);


    if (this.points[2])
      this.addAngleLabel();

    addHighlightAction(this, baseElement.firstElementChild);
  };

  ann.angleAction.prototype.addAngleLabel = function () {


    var element2 = document.createElement("div");

    var dx1 = this.points[2].x - this.points[1].x;
    var dy1 = this.points[2].y - this.points[1].y;
    var dx2 = this.points[0].x - this.points[1].x;
    var dy2 = this.points[0].y - this.points[1].y;
    var a1 = Math.atan2(dy1, dx1);
    var a2 = Math.atan2(dy2, dx2);
    var a = parseInt((a2 - a1) * 180 / Math.PI + 360) % 360;
    var angle = Math.atan2(dx1 * dy2 - dy1 * dx2, dx1 * dx2 + dy1 * dy2);
    if (angle < 0) {
      angle = angle * -1;
    }
    var degree_angle = angle * (180 / Math.PI);
    this.degreeAngle = Math.round(degree_angle);
    var clockwise = a > 180 ? true : false;

    element2.innerHTML = this.degreeAngle;
    //element2.style.transform = "rotate(" + angle + "deg)";
    //element2.style.transformOrigin = "left";
    element2.style.fontSize = this.textSize;
    //element2.style.fontWeight = "bold";
    element2.style.zIndex = "99";
    element2.style.color = this.textColor;
    element2.style.padding = "2px";
    //element2.style.backgroundColor = "rgba(0,0,0,0.4)";
    this.element2 = element2;

    this.element2.style.position = "absolute";
    this.element2.style.left = ((this.points[1].x - this.actionBoundaries.x) * 100 / this.actionBoundaries.width) + 15 + "%";
    this.element2.style.top = ((this.points[1].y - this.actionBoundaries.y - this.lineWidth / 2) * 100 / this.actionBoundaries.height) - 15 + "%";
    this.element.appendChild(element2);

    /*var arc = document.createElement("div");
    arc.setAttribute("id", "arc-annotation");
    arc.style.width = (degree_angle > 90 ? 20 : 18) + "px";
    arc.style.height = 14 + "px";
    //arc.style.zIndex = "2";
    // arc.style.borderRight = "20px solid " + this.color;
    //arc.style.borderRadius = "50%";
    arc.style.border = "3px solid " + this.color;
    // arc.style.borderBottom= "20px solid transparent";
    // arc.style.borderTop= "20px solid transparent";
    arc.style.borderTopLeftRadius = degree_angle+"px";
    arc.style.borderTopRightRadius = degree_angle+"px";
    arc.style.borderBottom = 0;
    arc.style.transform= clockwise ? "rotate(" + (degree_angle-15) + "deg)" : "rotate(-" + (degree_angle-15) + "deg)";

    //this.size = 20 * 4;

    //set reference to this.freeformElement
    this.arc = arc;

    this.arc.style.position = "absolute";
    this.arc.style.top = this.y2- (degree_angle > 90 ? 3 : 8) + "px";
    this.arc.style.left = (this.x2 - (clockwise ? 0 : (degree_angle > 90 ? 22 : 18))) + "px";
    baseElement.appendChild(arc);*/

  };

  ann.angleAction.prototype.deleteAnnotation = function () {
    // this.element.parentNode.removeChild(this.element);
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    if (this.element) {
      $(this.element).remove();
    }
    //this.element1.parentNode.removeChild(this.element1);
    //this.element2.parentNode.removeChild(this.element2);
    //this.arc.parentNode.removeChild(this.arc);
  };

  ann.angleAction.prototype.onSave = function (comment, areaPerimeterFlag, bookmarkFlag) {
    this.comment = comment;
    // this.areaPerimeterFlag = areaPerimeterFlag;
    //this.bookmarkFlag = bookmarkFlag;
  };

  ann.angleAction.prototype.getHighlightPosition = function () {
    var annotationPos = this.element.getClientRects()[0],
      annotationContainerPos = this.element.parentElement.getClientRects()[0],
      leftMostX = Math.min(annotationPos.left, annotationContainerPos.right - 506);
    return {
      x: Math.max(annotationPos.right, 0),
      y: Math.max(annotationPos.top, annotationContainerPos.top, 0)
    };
  };

}(Annotation));

(function (ann) {
  "use strict";
  ann.AnnoBoard = function (parentElemParam, canvasDimensions) {
    var selfBoard = this,
      canvasElement,
      onAnnotationCreated = typeof Event === "function" ? new Event('onAnnotationCreated') : window.event,
      onAnnotationDeleted = typeof Event === "function" ? new Event('onAnnotationDeleted') : window.event,
      onAnnotationEdited = typeof Event === "function" ? new Event('onAnnotationEdited') : window.event;
    this.allAnnotations = [];
    this.pixelToNanometer = 0;

    function getDimensions(baseElement) {
      //To Do: In case of OpenSeadragon, we should fetch proper image height width and top bottom positions here
      return baseElement.getClientRects()[0];
    }

    function createCanvasOverlay(element) {
      var canvasElem = document.createElement("canvas"),
        baseElementId = element.getAttribute("id");
      canvasElem.id = "canvas-overlay-" + selfBoard.id;
      //To Do: Check if below line is necessary
      canvasElem.style.position = "absolute";
      canvasElem.style.top = "0px";
      canvasElem.style.left = "0px";
      canvasElem.style.width = "100%";
      canvasElem.style.height = "100%";
      canvasElem.style.zIndex = "999999998";
      canvasElem.style.cursor = "crosshair";
      canvasElem.classList.add("canvasAnnotation");
      return canvasElem;
    }

    if (typeof parentElemParam === "string") {
      this.id = parentElemParam;
      this.parentElem = document.getElementById(this.id);
    } else {
      this.boardType = "OpenSeadragon";
      this.parentElem = parentElemParam.element;
      this.osdViewer = parentElemParam;
      this.id = this.parentElem.getAttribute("id");
    }

    this.canvasDimensions = canvasDimensions;

    if (!this.canvasDimensions) {
      this.canvasDimensions = getDimensions(this.parentElem);
    }

    canvasElement = createCanvasOverlay(this.parentElem);
    //To Do: Check if this is no harmful ever
    this.parentElem.style.position = "relative";
    this.parentElem.appendChild(canvasElement);

    this.overlayElem = canvasElement;
    this.actions = new ann.actions(this.overlayElem);
    this.actions.attachActions();
    //To Do: should hideOverlay belong to this or should that be private function?
    this.hideOverlay();

    this.annotations = [];
    this.implementedAction = null;

    //Openseadragon related event listeners
    //To Do: Put a check for openseadragon, so that we can use same events for other boards as well
    this.showAnnotationOnOsd = function (annotation, viewportRect) {
      if (selfBoard.boardType === "OpenSeadragon") {
        if (!viewportRect) {
          var presentRect = new OpenSeadragon.Rect(parseFloat(annotation.element.style.left), parseFloat(annotation.element.style.top), parseFloat(annotation.element.style.width), parseFloat(annotation.element.style.height));
          viewportRect = parentElemParam.viewport.viewerElementToViewportRectangle(presentRect);
        }
        parentElemParam.addOverlay(annotation.element, viewportRect);
      }
      selfBoard.allAnnotations.push(annotation);

      //sort all annotations
      selfBoard.allAnnotations.sort(function (a, b) {
        return b.size - a.size;
      });
      //update zIndex of elements
      var zIndex = 2,
        annIndex = 0;
      for (annIndex in selfBoard.allAnnotations) {
        var currentAnnotation = selfBoard.allAnnotations[annIndex];
        currentAnnotation.element.style.zIndex = zIndex++;
      }
    };

    this.saveAnnotation = function (ev) {
      if (ev.annotation.meetId && localStorage.isPresenter == "false") {
        if ($("#annotation-editor-button-cancel").length > 0) {
          $("#annotation-editor-button-cancel").trigger('click');
          return;
        }
      }
      var presentRect = new OpenSeadragon.Rect(parseFloat(ev.annotation.element.style.left), parseFloat(ev.annotation.element.style.top), parseFloat(ev.annotation.element.style.width), parseFloat(ev.annotation.element.style.height));
      var viewportRelativeRect = parentElemParam.viewport.viewerElementToViewportRectangle(presentRect);
      selfBoard.showAnnotationOnOsd(ev.annotation, viewportRelativeRect);
      onAnnotationCreated.annotation = ev.annotation;

      if (onAnnotationCreated.annotation.type === "Ruler" || onAnnotationCreated.annotation.type === "Arrow") {
        onAnnotationCreated.annotation.x1 += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.xPos : 0;
        onAnnotationCreated.annotation.y1 += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.yPos : 0;
        onAnnotationCreated.annotation.x2 += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.xPos : 0;
        onAnnotationCreated.annotation.y2 += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.yPos : 0;
      } else if (onAnnotationCreated.annotation.type === "Rectangular") {
        onAnnotationCreated.annotation.x += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.xPos : 0;
        onAnnotationCreated.annotation.y += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.yPos : 0;
      } else if (onAnnotationCreated.annotation.type === "Circular" || onAnnotationCreated.annotation.type === "Ellipse") {
        onAnnotationCreated.annotation.centerX += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.xPos : 0;
        onAnnotationCreated.annotation.centerY += (onAnnotationCreated.annotation.canvasInfo) ? onAnnotationCreated.annotation.canvasInfo.yPos : 0;
      } else if (onAnnotationCreated.annotation.type === "Angle" || onAnnotationCreated.annotation.type === "OpenFreeform" || onAnnotationCreated.annotation.type === "Freeform" || onAnnotationCreated.annotation.type === "FilledFreeform") {
        let i = ((onAnnotationCreated.annotation.type === "Freeform") || (onAnnotationCreated.annotation.type === "FilledFreeform")) ? 1 : 0;
        for (i; i < onAnnotationCreated.annotation.points.length; i++) {
          onAnnotationCreated.annotation.points[i].x += (onAnnotationCreated.annotation.canvasInfo) ? parseFloat(onAnnotationCreated.annotation.canvasInfo.xPos.toFixed(2)) : 0;
          onAnnotationCreated.annotation.points[i].y += (onAnnotationCreated.annotation.canvasInfo) ? parseFloat(onAnnotationCreated.annotation.canvasInfo.yPos.toFixed(2)) : 0;
        }
        onAnnotationCreated.annotation.actionBoundaries.x += (onAnnotationCreated.annotation.canvasInfo) ? parseFloat(onAnnotationCreated.annotation.canvasInfo.xPos.toFixed(2)) : 0;
        onAnnotationCreated.annotation.actionBoundaries.y += (onAnnotationCreated.annotation.canvasInfo) ? parseFloat(onAnnotationCreated.annotation.canvasInfo.yPos.toFixed(2)) : 0;
      }
      //To Do: convert points into 40x level points
      //osd.viewport.viewerElementToImageCoordinates(new OpenSeadragon.Point(annotation.x1, annotation.y1));
      selfBoard.parentElem.dispatchEvent(onAnnotationCreated);
    };
    selfBoard.parentElem.addEventListener("onAnnotationSave", this.saveAnnotation);

    this.deleteAnnotationOnOsd = function (ev) {
      if (selfBoard.boardType === "OpenSeadragon") {
        parentElemParam.removeOverlay(ev.annotation.element);
      }
      for (var ann in selfBoard.allAnnotations) {
        if (selfBoard.allAnnotations[ann].hasOwnProperty("id") && selfBoard.allAnnotations[ann].id === ev.annotation.id) {
          selfBoard.allAnnotations.splice(ann, 1);
          break;
        }
      }
    };

    this.deleteAnnotation = function (ev) {
      // selfBoard.deleteAnnotationOnOsd(ev);
      onAnnotationDeleted.annotation = ev.annotation;
      selfBoard.parentElem.dispatchEvent(onAnnotationDeleted);
    };
    selfBoard.parentElem.addEventListener("onAnnotationDelete", this.deleteAnnotation);

    this.editAnnotationOnOsd = function (ev) {
      if (selfBoard.boardType === "OpenSeadragon") {
        //osd.removeOverlay(ev.annotation.element);
      }
      onAnnotationEdited.annotation = ev.annotation;
      selfBoard.parentElem.dispatchEvent(onAnnotationEdited);
    };
    selfBoard.parentElem.addEventListener("onAnnotationEdit", this.editAnnotationOnOsd);
  };

  ann.AnnoBoard.prototype.setPixelToNanometer = function (value) {
    this.pixelToNanometer = value;
  };

  ann.AnnoBoard.prototype.checkHighlights = function () {
    var osd = this.osdViewer,
      pointerX = parseFloat(osd.pointerElem.style.left),
      pointerY = parseFloat(osd.pointerElem.style.top);
    for (var a in this.allAnnotations) {
      var annotation = this.allAnnotations[a].element,
        annotationX = parseFloat(annotation.style.left),
        annotationY = parseFloat(annotation.style.top),
        annotationX2 = parseFloat(annotation.style.width) + annotationX,
        annotationY2 = parseFloat(annotation.style.height) + annotationY;
      if (pointerX >= annotationX && pointerX <= annotationX2 && pointerY >= annotationY && pointerY <= annotationY2) {
        var position = this.allAnnotations[a].getHighlightPosition();
        ann.Editor("highlight", position, this.allAnnotations[a], osd.element);
        setTimeout(function () {
          ann.hideEditor();
        }, 2000);
      }
    }
  };

  ann.AnnoBoard.prototype.drawAnnotation = function (annotationData, isMarker) {
    this.showOverlay(false, annotationData);
    var tiledImage = this.osdViewer.world.getItemAt(0),
      ratio = tiledImage._scaleSpring.current.value *
        tiledImage.viewport._containerInnerSize.x /
        tiledImage.source.dimensions.x;
    annotationData.pixelToNanometer = this.pixelToNanometer / (ratio * this.osdViewer.viewport.getZoom(true));

    annotationData.osdObj = this.osdViewer;

    switch (annotationData.type) {
      case "OpenFreeform":
        this.implementedAction = new ann.freeformAction(annotationData);
        break;
      case "Rectangular":
        this.implementedAction = new ann.rectangularAction(annotationData);
        break;
      case "Circular":
        this.implementedAction = new ann.circularAction(annotationData);
        break;
      case "Freeform":
        this.implementedAction = new ann.closedFreeformAction(annotationData);
        break;
      case "FilledFreeform":
        this.implementedAction = new ann.closedFreeformAction(annotationData, null, true);
        break;
      case "Ruler":
        //To Do: Add a condition to check if base element is os
        this.implementedAction = new ann.rulerAction(annotationData);
        break;
      case "Arrow":
        this.implementedAction = new ann.arrowAction(annotationData);
        break;
      case "Ellipse":
        this.implementedAction = new ann.ellipticalAction(annotationData);
        break;
      case "Angle":
        this.implementedAction = new ann.angleAction(annotationData);
        break;
    }

    this.implementedAction.marker = isMarker;
    this.implementedAction.osdId = this.id;
    this.actions.setBehavior(this.implementedAction);
  };

  ann.AnnoBoard.prototype.showAnnotation = function (annotation) {
    var implementedAction;
    var tiledImage = this.osdViewer.world.getItemAt(0),
      ratio = tiledImage._scaleSpring.current.value *
        tiledImage.viewport._containerInnerSize.x /
        tiledImage.source.dimensions.x;
    annotation.pixelToNanometer = this.pixelToNanometer / (ratio * this.osdViewer.viewport.getZoom(true));
    switch (annotation.type) {
      case "OpenFreeform":
      case "Freeform":
        implementedAction = new ann.freeformAction(annotation);
        implementedAction.showAnnotation(annotation.points, this.parentElem);
        break;
      case "Rectangular":
        implementedAction = new ann.rectangularAction(annotation);
        implementedAction.showAnnotation(this.parentElem);
        break;
      case "Circular":
        implementedAction = new ann.circularAction(annotation);
        implementedAction.showAnnotation(this.parentElem);
        break;
      case "Ruler":
        implementedAction = new ann.rulerAction(annotation);
        //To Do: Add a condition to check if base element is osd
        var tiledImage = this.osdViewer.world.getItemAt(0),
          ratio = tiledImage._scaleSpring.current.value *
            tiledImage.viewport._containerInnerSize.x /
            tiledImage.source.dimensions.x;

        implementedAction.setConverter(this.pixelToNanometer / (ratio * this.osdViewer.viewport.getZoom(true)));
        implementedAction.showAnnotation(this.parentElem);
        break;
      case "Arrow":
        implementedAction = new ann.arrowAction(annotation);
        implementedAction.showAnnotation(this.parentElem);
        break;
      case "Ellipse":
        implementedAction = new ann.ellipticalAction(annotation);
        implementedAction.showAnnotation(this.parentElem);
        break;
      case "Angle":
        implementedAction = new ann.angleAction(annotation);
        implementedAction.showAnnotation(annotation.points, this.parentElem);
        break;
    }
    implementedAction.osdId = this.id;
    this.showAnnotationOnOsd(implementedAction);
    return implementedAction.element;
  };

  ann.AnnoBoard.prototype.removeAllAnnotations = function () {
    var annIndex;

    while (this.allAnnotations.length > 0) {
      var annotation = this.allAnnotations.pop();
      annotation.deleteAnnotation();
      this.deleteAnnotationOnOsd({
        annotation: annotation
      });
    }
  };

  ann.AnnoBoard.prototype.getAnnotations = function () {
    return this.annotations;
  };

  ann.AnnoBoard.prototype.getOverlay = function () {
    return this.overlayElem;
  };

  ann.AnnoBoard.prototype.showOverlay = function (isSplitview, annotationData) {
    var context = this.overlayElem.getContext("2d");
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    this.overlayElem.style.height = annotationData.canvasInfo.canvasDimension.height + 'px';
    this.overlayElem.style.width = annotationData.canvasInfo.canvasDimension.width + 'px';
    this.overlayElem.style.left = annotationData.canvasInfo.xPos + 'px';
    this.overlayElem.style.top = annotationData.canvasInfo.yPos + 'px';
    this.overlayElem.style.display = "block";
  };

  //To Do: should hideOverlay belong to this or should that be private function?
  ann.AnnoBoard.prototype.hideOverlay = function () {
    if (this.overlayElem) {
      this.overlayElem.style.display = "none";
    }
  };

  ann.AnnoBoard.prototype.destroyBoard = function () {
    this.parentElem.removeEventListener("onAnnotationSave", this.saveAnnotation);
    delete this.saveAnnotation;
    this.parentElem.removeEventListener("onAnnotationDelete", this.deleteAnnotation);
    delete this.deleteAnnotationOnOsd;
    this.parentElem.removeEventListener("onAnnotationEdit", this.editAnnotationOnOsd);
    delete this.editAnnotationOnOsd;
    if (this.overlayElem) {
      if (this.overlayElem.parentNode) {
        this.overlayElem.parentNode.removeChild(this.overlayElem);
      } else {
        delete this.overlayElem;
      }
    }
  };

}(Annotation));


(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // expose as amd module
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // expose as commonjs module
    module.exports = factory();
  } else {
    root.Annotation = factory();
  }
}(this, function () {
  return Annotation;
}));
