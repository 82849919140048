/* This file is create by Abhihsek and has dependency on "jquery"  */
(function () {

  if (typeof window.Event === "function") return false;

  function Event(event, params) {
    //params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('Event');
    evt.initEvent(event, true, false);
    //evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  Event.prototype = window.Event.prototype;

  window.Event = Event;
})();

function BatchAnnotations(OpenSeadragon) {
  BatchAnnotations.OSD = OpenSeadragon
  return function (osdObject) {
    return new BatchAnnotations.BatchAnnoBoard(osdObject);
  }
}
// closure for actions starts
(function (batchAnno) {
  "use strict";

  var hasTouch = !!window.hasOwnProperty('ontouchstart'),
    ACTIONS = {
      UP: hasTouch ? "touchstart" : "mouseup",
      DOWN: hasTouch ? "touchstart" : "mousedown",
      MOVE: hasTouch ? "touchmove" : "mousemove"
    };

  batchAnno.mouseActions = function (canvasOverlayElement) {
    this.canvasOverlayElement = canvasOverlayElement;
    this.implementedShape = null;
  }

  batchAnno.mouseActions.prototype.setShapeBehaviour = function (implementedShape) {
    this.implementedShape = implementedShape;
  }

  batchAnno.mouseActions.prototype.attachAction = function () {
    var myActions = this;
    var isMouseDown = false;

    function getRelativePoints(x, y) {
      var parentPos = myActions.canvasOverlayElement.getClientRects()[0];
      return {
        x: x - parentPos.left,
        y: y - parentPos.top
      };
    }
    this.mouseDownListener = function (event) {
      isMouseDown = true;
      myActions.canvasOverlayElement.height = myActions.canvasOverlayElement.getClientRects()[0].height;
      myActions.canvasOverlayElement.width = myActions.canvasOverlayElement.getClientRects()[0].width;

      myActions.implementedShape.getMouseDownPoints(getRelativePoints(event.x, event.y));
    }

    this.mouseUpListener = function (event) {
      myActions.implementedShape.getMouseUpPoints(getRelativePoints(event.x, event.y));
      isMouseDown = false;
    }

    this.mouseMoveListener = function (event) {
      if (isMouseDown) {
        // if (myActions.implementedShape.type == "Batch_Circular") {
        if (myActions.implementedShape.isDrawingComplete) {
          myActions.implementedShape.getMouseUpPoints(getRelativePoints(event.x, event.y));
          isMouseDown = false;
        } else {
          myActions.implementedShape.getMouseMovePoints(getRelativePoints(event.x, event.y));
        }
        // } else {
        //   myActions.implementedShape.getMouseMovePoints(getRelativePoints(event.x, event.y));
        // }
      }
    }

    myActions.canvasOverlayElement.addEventListener(ACTIONS.DOWN, this.mouseDownListener);
    myActions.canvasOverlayElement.addEventListener(ACTIONS.UP, this.mouseUpListener);
    myActions.canvasOverlayElement.addEventListener(ACTIONS.MOVE, this.mouseMoveListener);

    document.addEventListener(ACTIONS.UP, (mouseUp) => {
      if (isMouseDown && (myActions.implementedShape && !myActions.implementedShape.batchStarted && !myActions.implementedShape.isDrawingComplete)) {
        console.log("Mouse Up");

        var canvasWidth = myActions.implementedShape.canvasInfo.canvasDimension.width;
        var canvasHeight = myActions.implementedShape.canvasInfo.canvasDimension.height;

        var canvasX = myActions.implementedShape.canvasInfo.xPos;
        var canvasY = myActions.implementedShape.canvasInfo.yPos;

        var dispatchEvent = false;
        var mouseUpX = mouseUp.clientX;
        var mouseUpY = mouseUp.clientY;
        if (mouseUp.clientX < canvasX) {
          dispatchEvent = true;
          mouseUpX = canvasX;
        }
        if (mouseUp.clientY < canvasY) {
          dispatchEvent = true;
          mouseUpY = canvasY;
        }
        if (mouseUp.clientX > (canvasX + canvasWidth)) {
          dispatchEvent = true;
          mouseUpX = canvasX + canvasWidth;
        }
        if (mouseUp.clientY > (canvasY + canvasHeight)) {
          dispatchEvent = true;
          mouseUpY = canvasY + canvasHeight;
        }
        if (dispatchEvent) {
          let event = document.createEvent('MouseEvent');
          event.initMouseEvent(ACTIONS.UP, true, true, window, 0,
            mouseUp.screenX, mouseUp.screenY, mouseUpX, mouseUpY,
            event.ctrlKey, event.altKey, event.shiftKey, event.metaKey,
            0, null);
          myActions.canvasOverlayElement.dispatchEvent(event);
        }
      }
    });
  }

  batchAnno.mouseActions.prototype.detachAction = function (canvasOverlayElement) {
    if (this.mouseDownListener) {
      this.canvasOverlayElement.removeEventListener(ACTIONS.DOWN, this.mouseDownListener);
      // this.canvasOverlayElement.removeEventListener(ACTIONS.UP, this.mouseUpListener);
      // this.canvasOverlayElement.removeEventListener(ACTIONS.MOVE, this.mouseMoveListener);
      this.canvasOverlayElement.style.display = 'none';
    }
  }


}(BatchAnnotations));
// closure for actions ends

// closure for comment box starts
(function (batchAnno) {
  "use strict";

  batchAnno.Editor = function (action, implementedAction) {
    let position;
    let baseElement = implementedAction.OSD_parentElem;
    let area = implementedAction.area;
    let perimeter = implementedAction.perimeter;
    let hideEditor = false;
    if (action == "highlight") {
      hideEditor = true;
      $('#batch_editor').remove();
      position = implementedAction.highlighterPosition;
    } else {
      hideEditor = false;
      position = implementedAction.commentBoxStartPoints;
    }

    $('#' + implementedAction.osdId).append('<div id="batch_editor" class="batch_editor_main_div"><div class="anotatepop childDiv">' +
      '<div style="clear:both;">' +
      '<div class="float-left">' +
      '<label>Comment</label>' +
      '</div>' +
      '<div class="float-right icon_del_bokmark ">' +
      '<i class="fa fa-pencil" aria-hidden="true" id="annotation-popup-button-edit_batch" title="Edit"></i> ' +
      '<i class="fa fa-bookmark" aria-hidden="true" id="toggleBookmark"></i> ' +
      '<i class="fa fa-trash icon_del" aria-hidden="true" id="annotation-popup-button-delete_batch"></i>' +
      '</div>' +
      '</div>' +

      '<p class="viewer_email_annotatepop" id="userName" style="text-align: left !important; clear:both; padding-bottom: 0.4vw; display:none;"></p>' +
      '<textarea cols="39" rows="2" id="annotation-editor-text_batch" maxlength="1000"></textarea>' // text area
      +
      '<p class="errormsgs" id="error_Msg"></p>' +
      '<div class="area_perim">' +
      '<span title="Toggle Measurements" style="padding-right: .5vw;"><i class="fa fa-eye-slash" id="toggleMeasurements"></i></span> &nbsp;&nbsp;&nbsp;' +
      '<div id="measurements">' +
      '<div><label>Area: </label><span id="area" style="margin-left: 0.3vw;"></span></div>' +
      '<div><label>Perimeter: </label><span id="perimeter" style="margin-left: 0.3vw;"></span></div>' +
      '</div>' // end measurements div
      +
      '</div>' // end area_perim
      +
      '<div class="row filterbtn">' +
      '<div class="col-md-3"></div>' +
      '<div class="col-md-9 filterbtn" style="padding-right: 0;">' +
      '<button type="button" class="btn orangebtn savebtn" style="padding: .42vw 1.3vw; margin-left: .7vw;" aria-label="searchbtn" id="editor_done">Save</button>' +
      '<button type="button" class="btn btn-default" style="padding: .5vw 1.3vw;" aria-label="cancelbtn" id="editor_cancle">Close</button>' +
      '</div>' // end filterbtn
      +
      '</div>' // end row filterbtn
      +
      '</div>' // end popup
      +
      '</div>');

    if (action == 'highlight') {
      $(".batch_editor_main_div").css({
        'z-index': 999999998,
        'display': 'block',
        'position': 'absolute',
        'width': document.documentElement.scrollWidth + "px",
        'height': document.documentElement.scrollHeight + "px",
        "top": 0
      });
      let top = (position.y - 3);
      let left = (position.x - 38);
      $('.childDiv').css({
        'top': (top > 0 ? top : 0) + "px",
        'left': (left > 0 ? left : 0) + "px"
      });
      $('#annotation-editor-text_batch').val(implementedAction.comment);
      implementedAction.bookmarkFlag ? $('#toggleBookmark').show().addClass('icon_bookmark') : $('#toggleBookmark').hide().removeClass('icon_bookmark');
      $('#annotation-editor-text_batch').attr('disabled', 'disabled');
      $('#editor_done').hide();
      $('#editor_cancle').hide();
      $('#annotation-popup-button-edit_batch').show();
      $('#annotation-popup-button-delete_batch').show();
    } else {
      $(".batch_editor_main_div").css({
        'z-index': 999999998,
        'display': 'block',
        'position': 'absolute',
        'width': document.documentElement.scrollWidth + "px",
        'height': document.documentElement.scrollHeight + "px",
        "top": 0
      });
      let top = (position.y > (baseElement.getClientRects()[0].height - 200) ? (position.y - 200) : (position.y - Math.abs(implementedAction.height)));
      let left = (position.x > (baseElement.getClientRects()[0].width - 300) ? (position.x - (310 + Math.abs(implementedAction.width))) : (position.x + 10))
      $('.childDiv').css({
        'top': (top > 0 ? top : 0) + "px",
        'left': (left > 0 ? left : 0) + "px"
      });
      $('#annotation-editor-text_batch').removeAttr('disabled');
      $('#editor_done').text('Save');
      $('#annotation-popup-button-edit_batch').hide();
      $('#annotation-popup-button-delete_batch').hide();
    }

    $('#area').html(area.value + " " + area.unit);
    $('#perimeter').html(perimeter.value + " " + perimeter.unit);
    $('#userName').html(implementedAction.currentUser);

    $('#toggleMeasurements').click(function () {
      if ($(this).hasClass("fa fa-eye-slash")) {
        $(this).removeClass("fa-eye-slash")
        $(this).addClass("fa-eye")
        implementedAction.areaPerimeterFlag = false;
        $('#measurements').hide();
      } else {
        $(this).removeClass("fa-eye")
        $(this).addClass("fa-eye-slash")
        implementedAction.areaPerimeterFlag = true;
        $('#measurements').show();
      }
    });

    $('#toggleBookmark').click(function () {
      implementedAction.bookmarkFlag = !implementedAction.bookmarkFlag;
      if (implementedAction.bookmarkFlag) {
        $(this).addClass('icon_bookmark');
      } else {
        $(this).removeClass('icon_bookmark');
      }
    });

    $('#annotation-popup-button-edit_batch').click(function () {
      $('#annotation-editor-text_batch').removeAttr('disabled');
      $('#editor_done').show();
      $('#editor_cancle').show();
      $('#toggleBookmark').show()
    });

    $('#annotation-popup-button-delete_batch').click(function () {
      implementedAction.deleteAnnotation(implementedAction);
      $('#batch_editor').remove();
    });

    $('#editor_done').click(function () {
      if (action == 'highlight') {
        implementedAction.comment = $('#annotation-editor-text_batch').val();
        implementedAction.editAnnotation(implementedAction);
        $('#batch_editor').remove();
      } else {
        if ($('#annotation-editor-text_batch').val() == "") {
          $('#error_Msg').html("Please enter some comment");
        } else {
          implementedAction.batchStarted = true;
          $('#editor_done').text("").append('<span class="spinner-border spinner-border-sm"></span>&nbsp;Saving..');
          implementedAction.onSave($('#annotation-editor-text_batch').val());
          // $('#batch_editor').remove();
        }
      }
    });

    $('#editor_cancle').click(function () {
      $('#batch_editor').remove();
      if (action == 'showEditor') {
        implementedAction.cancleAnno();
      }
    });

    $('#batch_editor').on('mouseleave', function () {
      if (hideEditor)
        $('#batch_editor').remove();
    });

  };

}(BatchAnnotations));
// closure for comment box ends

// closure for rectangle starts
(function (batchAnno) {
  batchAnno.rectangleAnnotation = function (annotationData) {
    this.osdObj = annotationData.osdViewer;
    this.osdViewerObj = annotationData.osdViewer;
    this.OSD_parentElem = annotationData.parentElem;
    this.canvasOverlayElement = this.showCanvasOverlay(document.getElementById('canvas-overlay-for-batch' + this.osdViewerObj.id));
    this.contextCanvasOverlayEle = this.canvasOverlayElement.getContext('2d');
    this.type = "Batch_Rectangular";
    this.annoType = "Batch";
    this.element = null;
    this.color = "#0000FF"; // defines colour of shape (default blue)
    this.thickness = 1; // defines line width of shape (default 1)
    this.startX = 0; // position where drawing has started (x coordinate)
    this.startY = 0; // position where drawing has started (y coordinate)
    this.height = 0; // height is calculated on mouse up event
    this.width = 0; // width is calculated on mouse up event
    this.batchStarted = false; // to check if batch started                                                         
    this.comment = ''; // to store comment of shape
    this.index = 0; // increment id of annotation
    this.batchId = 0; // increment with each new batch
    this.pixelToNanometer = 0;
    this.areaPerimeterFlag = true;
    this.bookmarkFlag = false;
    this.canvasInfo = annotationData.canvasInfo;
    this.currentUser = annotationData.currentUser;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.isDrawingComplete = false;
  }

  batchAnno.rectangleAnnotation.prototype.setThickness = function (thickness) {
    this.thickness = thickness;
  }

  batchAnno.rectangleAnnotation.prototype.setColor = function (selectedColor) {
    this.color = selectedColor;
  }

  batchAnno.rectangleAnnotation.prototype.setPixelToNanometer = function (value) {
    this.pixelToNanometer = value;
  }

  batchAnno.rectangleAnnotation.prototype.getMouseDownPoints = function (relativePoints) {
    this.startX = relativePoints.x;
    this.startY = relativePoints.y;
    if (this.batchStarted && this.isDrawingComplete) {
      var showAnnotate = false;
      if (this.annotateDirectionX == 1) {
        if (this.canvasInfo.canvasDimension.width > (this.startX + (Math.abs(this.width) + 5))) {
          if (this.annotateDirectionY == 1) {
            if (this.canvasInfo.canvasDimension.height > (this.startY + (Math.abs(this.height) + 5))) {
              showAnnotate = true;
            }
          } else {
            if (this.startY > (Math.abs(this.height) + 5)) {
              showAnnotate = true;
            }
          }
        }
      } else {
        if (this.startX > (Math.abs(this.width) + 5)) {
          if (this.annotateDirectionY == 1) {
            if (this.canvasInfo.canvasDimension.height > (this.startY + (Math.abs(this.height) + 5))) {
              showAnnotate = true;
            }
          } else {
            if (this.startY > (Math.abs(this.height) + 5)) {
              showAnnotate = true;
            }
          }
        }
      }
      if (showAnnotate) {
        this.showAnnotationInDiv(this.OSD_parentElem);
      } else {
        showRectBoundaryExceedToast(this);
      }
    }
  }

  function showRectBoundaryExceedToast(mainObj) {
    if (mainObj.rectBoundaryTimer) {
      clearTimeout(mainObj.rectBoundaryTimer);
    }
    if ($("body").find("#boundaryToast").length == 0) {
      $("body").append('<div id="boundaryToast" style="display:none; background-color: #333; color: #fff; text-align: center; padding: 16px;' +
        'border-radius: 2px; position: fixed; z-index: 10000000; left: 50%; width: 650px; margin-left: -325px; top: 3.5vw; font-size: 17px;' +
        '">The annotation\'s width/height exceeds the boundary of the image. Please click somewhere else to draw the annotation </div>');
    }
    $("body").find("#boundaryToast").show();
    mainObj.rectBoundaryTimer = setTimeout(function () {
      $("body").find("#boundaryToast").hide();
    }, 5000);
  }

  batchAnno.rectangleAnnotation.prototype.getMouseMovePoints = function (relativePoints) {
    if (!this.batchStarted && !this.isDrawingComplete) {
      this.width = relativePoints.x - this.startX;
      this.height = relativePoints.y - this.startY;
      // this.annotateDirectionX = (this.startX < relativePoints.x) ? 1 : -1;// left-right = 1 && right-left = -1
      // this.annotateDirectionY = (this.startY < relativePoints.y) ? 1 : -1;// top-bottom = 1 && bottom-top = -1
      this.drawShape();
    }
  }
  batchAnno.rectangleAnnotation.prototype.getMouseUpPoints = function (relativePoints) {
    this.commentBoxStartPoints = {
      x: (relativePoints.x > this.startX ? relativePoints.x + this.canvasInfo.xPos : this.startX + this.canvasInfo.xPos) + 2,
      y: (relativePoints.y > this.startY ? relativePoints.y : this.startY) + 2
    }

    if (!this.batchStarted) {
      // this.batchId = ++this.batchId;

      if (this.width > this.startX) {
        this.width = (this.startX);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.width) + 2,
          y: (this.startY) + 2
        }
      }
      if (this.height > this.startY) {
        this.height = (this.startY);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.height) + 2,
          y: (this.startY) + 2
        }
      }
      if ((this.startX + this.width) > this.canvasOverlayElement.width) {
        this.width = (this.canvasOverlayElement.width - this.startX - 5);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.width) + 2,
          y: (this.startY) + 2
        }
      }
      if ((this.startY + this.height) > this.canvasOverlayElement.height) {
        this.height = (this.canvasOverlayElement.height - this.startY - 5);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.height) + 2,
          y: (this.startY) + 2
        }
      }

      this.width = relativePoints.x - this.startX;
      this.height = relativePoints.y - this.startY;
      this.annotateDirectionX = (this.startX < relativePoints.x) ? 1 : -1;// left-right = 1 && right-left = -1
      this.annotateDirectionY = (this.startY < relativePoints.y) ? 1 : -1;// top-bottom = 1 && bottom-top = -1
      this.drawShape("done");
      this.contextCanvasOverlayEle.clearRect(0, 0, parseInt(this.contextCanvasOverlayEle.canvas.style.width), parseInt(this.contextCanvasOverlayEle.canvas.style.height));
      showCommentBox(this);
    }
  }

  function showCommentBox(implementedAction) {
    batchAnno.Editor('showEditor', implementedAction);
  }

  batchAnno.rectangleAnnotation.prototype.drawShape = function (action) {
    this.removeShape();
    if (!this.batchStarted && !this.isDrawingComplete) {
      this.contextCanvasOverlayEle.strokeStyle = this.color;
      this.contextCanvasOverlayEle.lineWidth = this.thickness;

      var isDrawingAnnotationAllowed = true;
      if (this.width > 0 && this.height > 0) {// top-left to bottom-right
        if (((this.startY + Math.abs(this.height)) > (this.contextCanvasOverlayEle.canvas.height - 4)) || ((this.startX + Math.abs(this.width)) > (this.contextCanvasOverlayEle.canvas.width - 4))) {
          isDrawingAnnotationAllowed = false;
        }
      }

      if (this.width < 0 && this.height > 0) {// top-right to bottom-left
        if ((this.startX < (Math.abs(this.width) + 4)) || ((this.startY + Math.abs(this.height)) > (this.contextCanvasOverlayEle.canvas.height - 4))) {
          isDrawingAnnotationAllowed = false;
        }
      }

      if (this.width > 0 && this.height < 0) {// bottom-left to top-right
        if (((this.startX + Math.abs(this.width)) > (this.contextCanvasOverlayEle.canvas.width - 4)) || (this.startY < (Math.abs(this.height) + 4))) {
          isDrawingAnnotationAllowed = false;
        }
      }

      if (this.width < 0 && this.height < 0) {// bottom-right to top-left
        if ((this.startX < (Math.abs(this.width) + 4)) || (this.startY < (Math.abs(this.height) + 4))) {
          isDrawingAnnotationAllowed = false;
        }
      }

      // if (!((this.startX < this.width) || (this.startY < this.height) || ((this.startY + this.height) > (this.contextCanvasOverlayEle.canvas.height - 4)) || ((this.startX + this.width) > (this.contextCanvasOverlayEle.canvas.width - 4)))) {
      if (isDrawingAnnotationAllowed) {
        this.contextCanvasOverlayEle.clearRect(0, 0, parseInt(this.contextCanvasOverlayEle.canvas.style.width), parseInt(this.contextCanvasOverlayEle.canvas.style.height));
        this.contextCanvasOverlayEle.beginPath();
        this.contextCanvasOverlayEle.rect(this.startX, this.startY, this.width, this.height);
        this.contextCanvasOverlayEle.stroke();
        this.contextCanvasOverlayEle.closePath();

        if (action == "done") {
          this.isDrawingComplete = true;
          this.showAnnotationInDiv(this.OSD_parentElem);
        }
      } else {
        this.isDrawingComplete = true;
        this.showAnnotationInDiv(this.OSD_parentElem);
      }
    } else {
      this.isDrawingComplete = true;
      this.showAnnotationInDiv(this.OSD_parentElem);
    }
    this.calculateArea();
    this.calculatePerimeter();
  }

  batchAnno.rectangleAnnotation.prototype.showAnnotationInDiv = function (parentElem) {
    var rectangleDiv = document.createElement('div');
    rectangleDiv.setAttribute("id", "rectanglebatch_" + this.batchId + "_" + this.index);
    rectangleDiv.setAttribute("class", "annotaion");
    //set height and width of the canvas element
    rectangleDiv.style.width = Math.abs(this.width) + "px";
    rectangleDiv.style.height = Math.abs(this.height) + "px";
    rectangleDiv.style.zIndex = "2";
    rectangleDiv.style.border = this.thickness + "px solid " + this.color;

    this.size = Math.abs(this.width) * Math.abs(this.height);
    this.element = rectangleDiv;
    this.element.style.position = "absolute";
    this.element.style.top = this.height > 0 ? this.startY + (this.canvasInfo ? this.canvasInfo.yPos : 0) + "px" : this.startY + (this.canvasInfo ? this.canvasInfo.yPos : 0) + this.height + "px";
    this.element.style.left = this.width > 0 ? this.startX + (this.canvasInfo ? this.canvasInfo.xPos : 0) + "px" : this.startX + (this.canvasInfo ? this.canvasInfo.xPos : 0) + this.width + "px";

    parentElem.appendChild(this.element);
    this.pasteAnnotationOnOsd(this);
    addHighlightAction(this);
  }

  batchAnno.rectangleAnnotation.prototype.pasteAnnotationOnOsd = function (objectOfThis) {
    var presentRect = new batchAnno.OSD.Rect(parseFloat(objectOfThis.element.style.left), parseFloat(objectOfThis.element.style.top), parseFloat(objectOfThis.element.style.width), parseFloat(objectOfThis.element.style.height));
    var viewportRelativeRect = objectOfThis.osdViewerObj.viewport.viewerElementToViewportRectangle(presentRect);
    objectOfThis.osdViewerObj.addOverlay(objectOfThis.element, viewportRelativeRect);
    if (this.batchStarted) {
      this.saveAnno();
    }
  }

  batchAnno.rectangleAnnotation.prototype.onSave = function (comment) {
    this.comment = comment;
    this.drawShape();
  }

  batchAnno.rectangleAnnotation.prototype.saveAnno = function () {
    let onAnnotationCreated = new Event('onAnnotationCreated');
    onAnnotationCreated.annotation = this;
    onAnnotationCreated.annotation.startX = this.canvasInfo.xPos + this.startX;
    onAnnotationCreated.annotation.startY = this.canvasInfo.yPos + this.startY;
    this.OSD_parentElem.dispatchEvent(onAnnotationCreated)
  }

  batchAnno.rectangleAnnotation.prototype.removeShape = function () {
    if (this.isDrawingComplete) {
      this.isDrawingComplete = false;
      this.osdViewerObj.removeOverlay(this.element);
      $(this.element).remove();
    }
    this.contextCanvasOverlayEle.clearRect(0, 0, this.contextCanvasOverlayEle.canvas.width, this.contextCanvasOverlayEle.canvas.height);
  }

  batchAnno.rectangleAnnotation.prototype.cancleAnno = function () {
    this.removeShape();
    let onAnnotationCancle = new Event('onAnnotationCancel');
    onAnnotationCancle.annotation = this;
    this.OSD_parentElem.dispatchEvent(onAnnotationCancle)
  }

  batchAnno.rectangleAnnotation.prototype.deleteAnnotation = function (implementedAction) {
    let onAnnotationDeleted = new Event('onAnnotationDeleted');
    onAnnotationDeleted.annotation = implementedAction;
    onAnnotationDeleted.annotation.id = parseInt(onAnnotationDeleted.annotation.selectedAnno.attributes.annoid.value);
    this.OSD_parentElem.dispatchEvent(onAnnotationDeleted);
    $(implementedAction.selectedAnno).remove();
    this.osdViewerObj.removeOverlay(implementedAction.selectedAnno);
  };

  batchAnno.rectangleAnnotation.prototype.editAnnotation = function (data) {
    let onAnnotationEdited = new Event('onAnnotationEdited');
    this.areaPerimeterFlag = data.areaPerimeterFlag;
    this.bookmarkFlag = data.bookmarkFlag;
    onAnnotationEdited.annotation = data;
    onAnnotationEdited.annotation.id = data.selectedAnno.attributes.annoid.value;
    onAnnotationEdited.annotation.user = data.currentUser;
    this.OSD_parentElem.dispatchEvent(onAnnotationEdited);
  }

  function addHighlightAction(implementedAction) {
    $(implementedAction.element).mouseenter(function (event) {
      implementedAction.highlighterPosition = implementedAction.getHighlightPosition(event.target);
      implementedAction.selectedAnno = event.target;
      batchAnno.Editor('highlight', implementedAction);
    });
  }

  batchAnno.rectangleAnnotation.prototype.getHighlightPosition = function (element) {
    var annotationPos = element.getClientRects()[0],
      annotationContainerPos = element.parentElement.getClientRects()[0];
    return {
      x: ((annotationPos.right + 200) >= annotationContainerPos.width) ? (annotationPos.left - 495) : (annotationPos.right - 60),
      y: (annotationPos.top - 68)
    }
  };

  batchAnno.rectangleAnnotation.prototype.showCanvasOverlay = function (canvasOverlayElement) {
    var context = canvasOverlayElement.getContext('2d');
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    canvasOverlayElement.style.display = 'block';
    return canvasOverlayElement;
  }

  batchAnno.rectangleAnnotation.prototype.calculateArea = function () {
    var area = Math.abs((this.width * this.pixelToNanometer) * (this.height * this.pixelToNanometer));
    var unit = '';

    if (area < Math.pow(10, 6)) {
      area = area.toFixed(2);
      unit = "nm&sup2;";
    } else if (area < Math.pow(10, 12)) {
      area = (area / 1000000).toFixed(2);
      unit = "&mu;m&sup2;";
    } else if (area < Math.pow(10, 18)) {
      area = (area / Math.pow(10, 12)).toFixed(2);
      unit = "mm&sup2;";
    }
    this.area = {
      "value": area,
      "unit": unit
    };
  }

  batchAnno.rectangleAnnotation.prototype.calculatePerimeter = function () {
    var perimeter = 2 * Math.abs((this.width * this.pixelToNanometer) + (this.height * this.pixelToNanometer));
    var unit = '';
    if (perimeter < Math.pow(10, 3)) {
      perimeter = perimeter.toFixed(2);
      unit = "nm";
    } else if (perimeter < Math.pow(10, 6)) {
      perimeter = (perimeter / 1000).toFixed(2);
      unit = "&mu;m";
    } else if (perimeter < Math.pow(10, 9)) {
      perimeter = (perimeter / Math.pow(10, 6)).toFixed(2);
      unit = "mm";
    }
    this.perimeter = {
      "value": perimeter,
      "unit": unit
    };
  }

}(BatchAnnotations));
// closure for rectangle ends

// closuer for circle Starts
(function (batchAnno) {
  batchAnno.circleAnnotation = function (annotationData) {
    this.osdObj = annotationData.osdViewer;
    this.osdViewerObj = annotationData.osdViewer;
    this.OSD_parentElem = annotationData.parentElem;
    this.canvasOverlayElement = this.showCanvasOverlay(document.getElementById('canvas-overlay-for-batch' + this.osdViewerObj.id));
    this.contextCanvasOverlayEle = this.canvasOverlayElement.getContext('2d');
    this.type = "Batch_Circular";
    this.annoType = "Batch";
    this.color = "#0000FF"; // defines colour of shape (default blue)
    this.thickness = 1; // defines line width of shape (default 1)
    this.startX = 0; // position where drawing has started (x coordinate)
    this.startY = 0; // position where drawing has started (y coordinate)
    this.radius = 0;
    this.batchStarted = false; // to check if batch started                                                         
    this.comment = ''; // to store comment of shape
    this.index = 0; // increment id of annotation
    this.batchId = 0; // increment with each new batch
    this.pixelToNanometer = 0;
    this.areaPerimeterFlag = true;
    this.bookmarkFlag = false;
    this.height = 0;
    this.width = 0;
    this.canvasInfo = annotationData.canvasInfo;
    this.currentUser = annotationData.currentUser;
    this.slideId = annotationData.slideId;
    this.meetId = annotationData.meetId;
    this.isDrawingComplete = false;
  }

  batchAnno.circleAnnotation.prototype.setThickness = function (thickness) {
    this.thickness = thickness;
  }

  batchAnno.circleAnnotation.prototype.setColor = function (selectedColor) {
    this.color = selectedColor;
  }

  batchAnno.circleAnnotation.prototype.setPixelToNanometer = function (value) {
    this.pixelToNanometer = value;
  }

  function calculateDistance(point1, point2) {
    return Math.sqrt((point2.x - point1.x) * (point2.x - point1.x) + (point2.y - point1.y) * (point2.y - point1.y));
  }
  batchAnno.circleAnnotation.prototype.getMouseDownPoints = function (relativePoints) {
    this.startX = relativePoints.x;
    this.startY = relativePoints.y;
    if (this.batchStarted && this.isDrawingComplete) {
      var showAnnotate = false;
      if ((this.canvasInfo.canvasDimension.width > (this.startX + (Math.abs(this.width / 2) + 5))) && (this.startX > (Math.abs(this.width / 2) + 5))) {
        if ((this.canvasInfo.canvasDimension.height > (this.startY + (Math.abs(this.height / 2) + 5))) && (this.startY > (Math.abs(this.height / 2) + 5))) {
          showAnnotate = true;
        }
      }
      if (showAnnotate) {
        this.showAnnotationInDiv(this.OSD_parentElem);
      } else {
        showCircleBoundaryExceedToast(this);
      }
    } else {
      if (this.batchStarted) {
        this.showAnnotationInDiv(this.OSD_parentElem);
      }
    }
  }

  function showCircleBoundaryExceedToast(mainObj) {
    if (mainObj.circleBoundaryTimer) {
      clearTimeout(mainObj.circleBoundaryTimer);
    }
    if ($("body").find("#boundaryToast").length == 0) {
      $("body").append('<div id="boundaryToast" style="display:none; background-color: #333; color: #fff; text-align: center; padding: 16px;' +
        'border-radius: 2px; position: fixed; z-index: 10000000; left: 50%; width: 650px; margin-left: -325px; top: 3.5vw; font-size: 17px;' +
        '">The annotation\'s width/height exceeds the boundary of the image. Please click somewhere else to draw the annotation </div>');
    }

    $("body").find("#boundaryToast").show();
    mainObj.circleBoundaryTimer = setTimeout(function () {
      $("body").find("#boundaryToast").hide();
    }, 5000);
  }

  batchAnno.circleAnnotation.prototype.getMouseMovePoints = function (relativePoints) {
    if (!this.batchStarted && !this.isDrawingComplete) {
      this.radius = calculateDistance(relativePoints, {
        x: this.startX,
        y: this.startY
      });
      if (this.radius < 0) {
        this.radius = 0;
      }
      this.drawShape();
    }
  }
  batchAnno.circleAnnotation.prototype.getMouseUpPoints = function (relativePoints) {
    this.commentBoxStartPoints = {
      x: (relativePoints.x > this.startX ? relativePoints.x + this.canvasInfo.xPos : this.startX + this.canvasInfo.xPos) + 2,
      y: (relativePoints.y > this.startY ? relativePoints.y : this.startY) + 2
    }

    // if (!this.element) {
    //   this.showAnnotationInDiv(this.OSD_parentElem);
    // }

    if (!this.batchStarted) {
      this.radius = calculateDistance(relativePoints, {
        x: this.startX,
        y: this.startY
      });
      if (this.radius > this.startX) {
        this.radius = (this.startX);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.radius) + 2,
          y: (this.startY) + 2
        }
      }
      if (this.radius > this.startY) {
        this.radius = (this.startY);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.radius) + 2,
          y: (this.startY) + 2
        }
      }
      if ((this.startX + this.radius) > this.canvasOverlayElement.width) {
        this.radius = (this.canvasOverlayElement.width - this.startX - 5);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.radius) + 2,
          y: (this.startY) + 2
        }
      }
      if ((this.startY + this.radius) > this.canvasOverlayElement.height) {
        this.radius = (this.canvasOverlayElement.height - this.startY - 5);
        this.commentBoxStartPoints = {
          x: (this.startX + this.canvasInfo.xPos + this.radius) + 2,
          y: (this.startY) + 2
        }
      }

      this.height = this.width = (2 * this.radius);
      this.drawShape("done");
      this.contextCanvasOverlayEle.clearRect(0, 0, this.contextCanvasOverlayEle.canvas.width, this.contextCanvasOverlayEle.canvas.height);
      showCommentBox(this);
    } /* else {
      if (this.isDrawingComplete)
        showCommentBox(this);
    } */
  }

  function showCommentBox(implementedAction) {
    batchAnno.Editor('showEditor', implementedAction);
  }

  batchAnno.circleAnnotation.prototype.drawShape = function (action) {
    this.removeShape();
    if (!this.batchStarted && !this.isDrawingComplete) {
      this.contextCanvasOverlayEle.strokeStyle = this.color;
      this.contextCanvasOverlayEle.lineWidth = this.thickness;
      if (this.radius < 0) {
        this.radius = 0;
      }
      if (!((this.startX < this.radius) || (this.startY < this.radius) || ((this.startY + this.radius) > this.contextCanvasOverlayEle.canvas.height) || ((this.startX + this.radius) > this.contextCanvasOverlayEle.canvas.width))) {
        console.log("radius: " + this.radius);
        this.contextCanvasOverlayEle.clearRect(0, 0, this.contextCanvasOverlayEle.canvas.width, this.contextCanvasOverlayEle.canvas.height)
        this.contextCanvasOverlayEle.beginPath();
        this.contextCanvasOverlayEle.arc(this.startX, this.startY, this.radius, 0, 2 * Math.PI);
        this.contextCanvasOverlayEle.stroke();
        this.contextCanvasOverlayEle.closePath();
        if (action == "done") {
          this.isDrawingComplete = true;
          this.showAnnotationInDiv(this.OSD_parentElem);
        }
      } else {
        this.isDrawingComplete = true;
        this.showAnnotationInDiv(this.OSD_parentElem);
      }
    } else {
      this.isDrawingComplete = true;
      this.showAnnotationInDiv(this.OSD_parentElem);
    }
    this.calculateArea();
    this.calculatePerimeter();
  }

  batchAnno.circleAnnotation.prototype.showAnnotationInDiv = function (parentElem) {
    // this.removeShape();
    var element = document.createElement("div");
    element.setAttribute("id", "circular-annotation-batch");
    element.setAttribute("class", "annotaion");
    //set height and width of the canvas element
    element.style.width = this.radius * 2 + "px";
    element.style.height = this.radius * 2 + "px";
    element.style.zIndex = "2";
    element.style.border = this.thickness + "px solid " + this.color;
    element.style.borderRadius = "50%";

    this.size = this.radius * 4;
    //set reference to this.freeformElement
    this.element = element;

    this.points = [];

    this.element.style.position = "absolute";
    this.element.style.top = this.startY + (this.canvasInfo ? this.canvasInfo.yPos : 0) - this.radius + "px";
    this.element.style.left = this.startX + (this.canvasInfo ? this.canvasInfo.xPos : 0) - this.radius + "px";

    parentElem.appendChild(this.element);
    this.pasteAnnotationOnOsd(this);
    addHighlightAction(this);
  }

  batchAnno.circleAnnotation.prototype.pasteAnnotationOnOsd = function (objectOfThis) {
    var presentRect = new batchAnno.OSD.Rect(parseFloat(objectOfThis.element.style.left), parseFloat(objectOfThis.element.style.top), parseFloat(objectOfThis.element.style.width), parseFloat(objectOfThis.element.style.height));
    var viewportRelativeRect = objectOfThis.osdViewerObj.viewport.viewerElementToViewportRectangle(presentRect);
    objectOfThis.osdViewerObj.addOverlay(objectOfThis.element, viewportRelativeRect);
    if (this.batchStarted) {
      this.saveAnno();
    }
  }

  batchAnno.circleAnnotation.prototype.onSave = function (comment) {
    this.comment = comment;
    this.saveAnno();
  }

  batchAnno.circleAnnotation.prototype.saveAnno = function () {
    let onAnnotationCreated = new Event('onAnnotationCreated');
    onAnnotationCreated.annotation = this;
    onAnnotationCreated.annotation.startX = this.canvasInfo.xPos + this.startX;
    onAnnotationCreated.annotation.startY = this.canvasInfo.yPos + this.startY;
    this.OSD_parentElem.dispatchEvent(onAnnotationCreated)
  }

  batchAnno.circleAnnotation.prototype.removeShape = function () {
    if (this.isDrawingComplete) {
      this.isDrawingComplete = false;
      this.osdViewerObj.removeOverlay(this.element);
      $(this.element).remove();
    }
    this.contextCanvasOverlayEle.clearRect(0, 0, this.contextCanvasOverlayEle.canvas.width, this.contextCanvasOverlayEle.canvas.height);
  }

  batchAnno.circleAnnotation.prototype.cancleAnno = function () {
    this.removeShape();
    let onAnnotationCancle = new Event('onAnnotationCancel');
    onAnnotationCancle.annotation = this;
    this.OSD_parentElem.dispatchEvent(onAnnotationCancle)
  }

  batchAnno.circleAnnotation.prototype.deleteAnnotation = function (implementedAction) {
    let onAnnotationDeleted = new Event('onAnnotationDeleted');
    onAnnotationDeleted.annotation = implementedAction;
    onAnnotationDeleted.annotation.id = parseInt(onAnnotationDeleted.annotation.selectedAnno.attributes.annoid.value);
    this.OSD_parentElem.dispatchEvent(onAnnotationDeleted);
    $(implementedAction.selectedAnno).remove();
    this.osdViewerObj.removeOverlay(implementedAction.selectedAnno);
  };

  batchAnno.circleAnnotation.prototype.editAnnotation = function (data) {
    let onAnnotationEdited = new Event('onAnnotationEdited');
    this.comment = data.comment;
    this.areaPerimeterFlag = data.areaPerimeterFlag;
    this.bookmarkFlag = data.bookmarkFlag;
    onAnnotationEdited.annotation = data;
    onAnnotationEdited.annotation.id = data.selectedAnno.attributes.annoid.value;
    onAnnotationEdited.annotation.user = data.currentUser;
    this.OSD_parentElem.dispatchEvent(onAnnotationEdited);
  }

  function addHighlightAction(implementedAction) {
    $(implementedAction.element).mouseenter(function (event) {
      implementedAction.highlighterPosition = implementedAction.getHighlightPosition(event.target);
      implementedAction.selectedAnno = event.target;
      batchAnno.Editor('highlight', implementedAction);
    });
  }

  batchAnno.circleAnnotation.prototype.getHighlightPosition = function (element) {
    var annotationPos = element.getClientRects()[0],
      annotationContainerPos = element.parentElement.getClientRects()[0];
    return {
      x: ((annotationPos.right + 200) >= annotationContainerPos.width) ? (annotationPos.left - 495) : (annotationPos.right - 60),
      y: (annotationPos.top - 68)
    }
  };

  batchAnno.circleAnnotation.prototype.showCanvasOverlay = function (canvasOverlayElement) {
    var context = canvasOverlayElement.getContext('2d');
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    canvasOverlayElement.style.display = 'block';
    return canvasOverlayElement;
  }

  batchAnno.circleAnnotation.prototype.calculateArea = function () {
    var area = (Math.PI * Math.pow(this.radius, 2));
    var unit = '';

    if (area < Math.pow(10, 6)) {
      area = area.toFixed(2);
      unit = "nm&sup2;";
    } else if (area < Math.pow(10, 12)) {
      area = (area / 1000000).toFixed(2);
      unit = "&mu;m&sup2;";
    } else if (area < Math.pow(10, 18)) {
      area = (area / Math.pow(10, 12)).toFixed(2);
      unit = "mm&sup2;";
    }
    this.area = {
      "value": area,
      "unit": unit
    };
  }

  batchAnno.circleAnnotation.prototype.calculatePerimeter = function () {
    var perimeter = (2 * Math.PI * this.radius);
    var unit = '';
    if (perimeter < Math.pow(10, 3)) {
      perimeter = perimeter.toFixed(2);
      unit = "nm";
    } else if (perimeter < Math.pow(10, 6)) {
      perimeter = (perimeter / 1000).toFixed(2);
      unit = "&mu;m";
    } else if (perimeter < Math.pow(10, 9)) {
      perimeter = (perimeter / Math.pow(10, 6)).toFixed(2);
      unit = "mm";
    }
    this.perimeter = {
      "value": perimeter,
      "unit": unit
    };
  }

}(BatchAnnotations));
// closuer for circle Ends

(function (batchAnno) {
  'use strict'

  batchAnno.BatchAnnoBoard = function (osdObject) {
    this.pixelToNanometer = 0;
    if (osdObject) { // create osd as parent element
      this.boardType = "OpenSeadragon";
      this.parentElem = osdObject.element;
      this.osdViewer = osdObject;
      this.id = this.parentElem.getAttribute("id");
    }
    var selfboard = this;

    // 1) create a canvas with dimensions = dimensions of osd canvas
    function getDimensions(osdParentElem) {
      return osdParentElem.getClientRects()[0];
    }
    this.osdCanvasDimensions = getDimensions(this.parentElem);

    // 2) create canvas, 
    function createCanvasOverlay(parentElem) {
      var canvasElem = document.createElement("canvas");
      canvasElem.id = "canvas-overlay-for-batch" + selfboard.id;
      //To Do: Check if below line is necessary
      canvasElem.style.position = "absolute";
      canvasElem.style.top = "0px";
      canvasElem.style.left = "0px";
      canvasElem.style.width = "100%";
      canvasElem.style.height = "100%";
      canvasElem.style.zIndex = "9";
      canvasElem.style.cursor = "crosshair";
      canvasElem.classList.add("canvasBatchAnnotation");
      return canvasElem;
    }

    this.canvasOverlayElement = createCanvasOverlay(this.parentElem);

    // 3) append the canvas to parentElem
    this.parentElem.appendChild(this.canvasOverlayElement);

    this.actions = new batchAnno.mouseActions(this.canvasOverlayElement);
    this.actions.attachAction();

    this.hideCanvasOverlay();
  }



  batchAnno.BatchAnnoBoard.prototype.showOverlay = function (isSplitview, annotationData) {
    if (isSplitview) {
      var canvas1 = document.getElementById('canvas-overlay-openseadragon1');
      var canvas2 = document.getElementById('canvas-overlay-openseadragon2');

      var context1 = canvas1.getContext('2d');
      var context2 = canvas2.getContext('2d');

      context1.clearRect(0, 0, context1.canvas.width, context1.canvas.height);
      context2.clearRect(0, 0, context2.canvas.width, context2.canvas.height);

      canvas1.style.display = "block";
      canvas2.style.display = "block";

    } else {

      var context = this.canvasOverlayElement.getContext("2d");
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      this.canvasOverlayElement.style.height = annotationData.canvasInfo.canvasDimension.height + 'px';
      this.canvasOverlayElement.style.width = annotationData.canvasInfo.canvasDimension.width + 'px';
      this.canvasOverlayElement.style.left = annotationData.canvasInfo.xPos + 'px';
      this.canvasOverlayElement.style.top = annotationData.canvasInfo.yPos + 'px';
      this.canvasOverlayElement.style.display = "block";
    }
  };

  batchAnno.BatchAnnoBoard.prototype.drawBatchAnnotations = function (annotationData) {
    this.showOverlay(false, annotationData);
    annotationData.parentElem = this.parentElem;
    annotationData.osdViewer = this.osdViewer;

    switch (annotationData.type) {
      case 'Rectangle':
        this.implementedShape = new batchAnno.rectangleAnnotation(annotationData);
        break;
      case 'Circle':
        this.implementedShape = new batchAnno.circleAnnotation(annotationData);
        break;
      default:
        this.hideCanvasOverlay();
    }
    this.implementedShape.setColor(annotationData.colour);
    this.implementedShape.setThickness(annotationData.thickness);
    this.implementedShape.setPixelToNanometer(this.pixelToNanometer)
    this.implementedShape.osdId = this.id;
    this.actions.setShapeBehaviour(this.implementedShape);
  }
  batchAnno.BatchAnnoBoard.prototype.hideCanvasOverlay = function () {
    this.canvasOverlayElement.style.display = 'none';
  }

  batchAnno.BatchAnnoBoard.prototype.finishedChanges = function () {
    this.hideCanvasOverlay();
  }

  batchAnno.BatchAnnoBoard.prototype.setPixelToNanometer = function (value) {
    this.pixelToNanometer = value;
  };

}(BatchAnnotations));


// function to export file
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // expose as amd module
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // expose as commonjs module
    module.exports = factory();
  } else {
    root.BatchAnnotations = factory();
  }
}(this, function () {
  return BatchAnnotations;
}));
